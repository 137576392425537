import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from '../../../AssetManagementTable'
import {ModalTitle, ModalWrap} from './battery-list-modal.styles'
import DeviceSite from 'Components/AssetManagementBase/components/device-assigned-site'
import { Link } from 'react-router-dom'


export const columns = [
  {field: 'serialNumber', translation: 'AssetManagementTable_serialNumber', render: null},
  {field: 'batteryPercentage', translation: 'AssetManagementTable_lowHealth', render: ({ value }) => <p>{value} %</p>},
  {field: 'currentDevice', translation: 'AssetManagementTable_deviceName', render: ({ value }) => <Link to={`/assets/management/devices/${value}`}>{value}</Link>},
  { field: 'currentDeviceAlias', translation: 'AssetManagementTable_deviceAlias', render: null },
  {field: 'site', translation: 'AssetManagementTable_site',  render: ({ value }) => <DeviceSite cellData={value} />}
]

const BatteryListModal = ({data, t}) => (
  <ModalWrap>
    <ModalTitle>{t('BatteryLowHealth_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable data={data} columns={columns} fileName={t('BatteryLowHealth_title')} />
    </Modal.Content>
  </ModalWrap>
)

export default BatteryListModal
