import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from 'Components/AssetManagementTable'
import { ModalWrap, ModalTitle } from './battery-list-modal.styles'
import { Link } from 'react-router-dom'
import DeviceSite from 'Components/AssetManagementBase/components/device-assigned-site'

export const columns = [
  { field: 'serialNumber', translation: 'AssetManagementTable_serialNumber', render: null },
  { field: 'cycleCount', translation: 'AssetManagementTable_cycles', render: null },
  { field: 'currentDevice', translation: 'AssetManagementTable_deviceName', render: ({ value }) => <Link to={`/assets/management/devices/${value}`}>{value}</Link> },
  { field: 'currentDeviceAlias', translation: 'AssetManagementTable_deviceAlias', render: null },
  { field: 'site', translation: 'AssetManagementTable_site',  render: ({ value }) => <DeviceSite cellData={value} />},

]

const BatteryListModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>{t('BatteryCycleCount_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable data={data} columns={columns} fileName={t('BatteryCycleCount_title')} />
    </Modal.Content>
  </ModalWrap>
)

export default BatteryListModal
