import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Icon, Button } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import DeviceActions from '../Redux/DeviceRedux';
import ModalActions from '../Redux/ModalRedux';
import { useHistory } from 'react-router-dom';
import { currentDeviceSelector } from '../Selectors/DeviceSelector';
import { useTranslate } from 'react-translate';
import styled from 'styled-components/macro';
import DeviceSite from 'Components/AssetManagementBase/components/device-assigned-site';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationUsageExportApi } from 'Services';
import { toast } from 'react-toastify';
import { ErrorToast, SuccessToast } from 'Themes/ScufStyledComponents';
import { getStartDate, getEndDate } from 'Utils/MomentHelpers';
import UpdatesActions from 'Redux/UpdatesRedux';

const ExportWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem 0 0;
  & .h-icon {
    margin-right: 0.3rem;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.theme.black ? '#fff' : '#303030')} !important;
    font-weight: 400;
  }
  margin-top: ${(props) => (props.heigthDrill ? '-2.7rem' : '0rem')};
  margin-bottom: ${(props) => (props.heigthDrill ? '0rem' : '1rem')};
  @media (max-width: 768px) {
    margin: 0.5rem 0rem;
  }
`;
const StyledDataTable = styled(DataTable)`
  margin-top: ${(props) => (props.heigthDrill ? '2rem' : '0rem')};
  @media (max-width: 768px) {
    margin-top: 0rem;
  }
  & .p-datatable-wrapper {
    height: ${(props) => (props.columnsInData ? '26rem' : '4.5rem')};
  }
  & .cell-data-wrap {
    padding: 0 0 0 1.5rem !important;
    font-size: 0.875rem;
    @media (max-width: 768px) {
      padding: 0 !important;
    }
  }
  & .p-datatable-row {
    height: 32px !important;
  }
  & .p-checkbox-box {
    max-width: 1rem !important;
    max-height: 1rem !important;
  }
  & .p-checkbox-icon {
    font-size: 0.5rem !important;
    top: -3px !important;
  }
  & .p-datatable-header {
    max-height: 3rem;
    background-color: #fff !important;
  }
  & .input-wrap {
    margin: 0.25rem 0 0.25rem 0;
  }
  & .total-count {
    font-size: 0.875rem;
    font-weight: 400;
  }
  & .ui.forge-datatable-wrap .select-all-checkbox {
    z-index: ${(props) => (props.heigthDrill ? '0' : '1')} !important;
  }
`;
const siteRenderer = ({ field, value, rowData }) => {
  return <DeviceSite cellData={value} />;
};
const AssetManagementTable = ({
  siteId,
  data,
  columns,
  totalRows,
  totalLengthData = 100,
  pageSize = 100,
  isLoading = false,
  nextPageApi = null,
  onSearch,
  startDate,
  endDate,
  today,
  expanderTemplate,
  isDetailsTable = false,
  deviceNameDetail,
  fileName,
  theme,
  heigthDrill,
  expandedRows,
  columnsInData = false,
  rowExpansionTemplate,
  loading,
  onSearchDetails = true,
  noSelectionMode,
  disableRowClick,
  onRowClick,
  isTagManagement,
  onEditDevicesTags,
  exportApi = () => {},
  filterKey,
  setFilterKey,
}) => {
  const t = useTranslate('AssetManagementTable');
  const history = useHistory();
  const dispatch = useDispatch();
  const devices = useSelector((state) => currentDeviceSelector(state));
  const state = useSelector((state) => state);
  const language = useSelector(({ user }) => user.getIn(['language'], []))
  const [dataTableData, setDataTableData] = useState(data);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(pageSize * 2);
  const searchCondition = fileName === 'applicationUsage' || fileName === 'networkIO'
    ? isDetailsTable ? t('Search_Details') : t('Search_Table')
    : isDetailsTable ? t('Search_Details') : t('Search_Table_For')
  const setLoading = (state) => dispatch(UpdatesActions.updatesSetLoading(state));
  useEffect(() => {
    if (data.length === totalRows) {
      setDataTableData(data);
    } else {
      slicedData({ first, rows });
    }
  }, [data]);

  const getExportApplicationUsage = async () => {
    const FormatedStartDate = getStartDate(startDate);
    const FormatedEndDate = getEndDate(endDate);
    setLoading(true);
    const resp = await ApplicationUsageExportApi.getExportFile(siteId, FormatedStartDate, FormatedEndDate, fileName, language);
    if (resp.ok) {
      const path = '/reports/downloads';
      toast(<SuccessToast message={t('ExportToastSuccess')} detailsClick={() => history.push(path)} detailsText={t('ExportTextDetails')} />);
    } else {
      toast(<ErrorToast message={t('Export_Failed')} />);
    }
    setLoading(false);
  };

  const getExportApplicationUsageByName = async () => {
    if (isDetailsTable) {
      const FormatedStartDate = getStartDate(startDate);
      const FormatedEndDate = getEndDate(endDate);
      setLoading(true);
      const resp = await ApplicationUsageExportApi.getExportFileDetailByName(
        siteId,
        deviceNameDetail,
        FormatedStartDate,
        FormatedEndDate,
        fileName,
        language
      );
      if (resp.ok) {
        const path = '/reports/downloads';
        toast(<SuccessToast message={t('ExportToastSuccess')} detailsClick={() => history.push(path)} detailsText={t('ExportTextDetails')} />);
      } else {
        toast(<ErrorToast message={t('Export_Failed')} />);
      }
      setLoading(false);
    }
  };


  const downloadProvisonResults = () => {
    setLoading(true);
    const csv = 'data:text/csv;charset=utf-8,' + encodeURI(parseProvisionResultsToCsvFormat());
    const link = document.createElement('a');
    link.href = csv;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  };

  const parseProvisionResultsToCsvFormat = () => {
    const dataToExport = selectedRow.length === 0 ? data : selectedRow;
    const header = columns.map((value) => t(value.translation));
    let csvText = header.join(',') + '\n';
    dataToExport.map((row) => {
      csvText +=
        columns
          .map((column) =>
            typeof row[column.field] === 'string' && row[column.field]?.includes(',')
              ? `"${row[column.field]}"`
              : row[column.field]
          )
          .join(',') + '\n';
    });
    return csvText;
  };

  const redirectToDetails = ({ data }) => {
    dispatch(DeviceActions.deviceCurrent(data));
    const path = data.currentDevice ? `/assets/management/devices/${data.currentDevice}` : '/assets/management/all';
    history.push(path);
    dispatch(ModalActions.closeModal());
  };
  const nextPageApiCall = (currentPage, pages) => {
    if (currentPage >= pages) {
      setIsDone(true);
    }
    if (startDate && endDate) {
      isDetailsTable
        ? nextPageApi(deviceNameDetail, siteId, {
            page: currentPage,
            pagesize: pageSize,
            startDate: getStartDate(startDate),
            endDate: getEndDate(endDate),
          })
        : nextPageApi(siteId, {
            page: currentPage,
            pagesize: pageSize,
            startDate: getStartDate(startDate),
            endDate: getEndDate(endDate),
            filterKey: filterKey,
          });
    } else {
      isDetailsTable
        ? nextPageApi(deviceNameDetail, siteId, {
            page: currentPage,
            pagesize: pageSize,
            startDate: new Date(new Date().setUTCHours(0, 0, 0)).toISOString(),
            endDate: new Date(new Date().setUTCHours(23, 59, 59)).toISOString(),
          })
        : nextPageApi(siteId, {
            page: currentPage,
            pagesize: pageSize,
            startDate: new Date(new Date().setUTCHours(0, 0, 0)).toISOString(),
            endDate: new Date(new Date().setUTCHours(23, 59, 59)).toISOString(),
            filterKey: filterKey,
          });
    }
  };
  const slicedData = ({ first, rows }) => {
    let last = first + rows;
    if (first + rows > totalRows) {
      last = totalRows;
    }
    setDataTableData([...data].slice(first, last));
  };
  const handleScroll = ({ first, rows }) => {
    const pages = Math.ceil(totalRows / (pageSize || 1) || 1);
    const currentRows = data.length || 0;
    const currentPage = (Math.ceil(currentRows / pageSize || 1) || 1) + 1;
    setFirst(first);
    setRows(rows);
    if (currentRows > 20 && currentPage <= pages) {
      nextPageApiCall(currentPage, pages);
    }
  };
  return (
    <>
      <ExportWrap theme={theme} heigthDrill={heigthDrill}>
        {isDetailsTable ? (
          <Button
            icon={<Icon root="building" name="export" size="medium" />}
            type="link"
            content={t('Export')}
            onClick={getExportApplicationUsageByName}
          />
        ) : fileName === 'applicationUsage' || fileName === 'networkIO' ? (
          <Button
            icon={<Icon root="building" name="export" size="medium" />}
            type="link"
            content={t('Export')}
            onClick={getExportApplicationUsage}
          />
        ) : (
          <Button
            icon={<Icon root="building" name="export" size="medium" />}
            type="link"
            content={t('Export')}
            onClick={downloadProvisonResults}
          />
        )}
      </ExportWrap>
      <div>
        <StyledDataTable
          data={dataTableData}
          totalRecords={totalRows}
          lazy={nextPageApi ? (totalRows < 100 ? false : true) : false}
          virtualScroll={nextPageApi ? (totalRows < 100 ? false : true) : false}
          onVirtualScroll={nextPageApi ? (isDone ? slicedData : handleScroll) : null}
          rows={nextPageApi ? totalLengthData : data.length}
          virtualRowHeight={47}
          scrollable={true}
          scrollHeight={!isDetailsTable ? '55vh' : '30vh'}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={() => {}}
          heigthDrill={heigthDrill}
          expandedRows={expandedRows}
          columnsInData={columnsInData}
          rowExpansionTemplate={rowExpansionTemplate}
          loading={loading}
          search={true}
          selection={noSelectionMode ? null : selectedRow}
          metaKeySelection={false}
          selectionMode={noSelectionMode ? null : !data.length ? 'single' : 'multiple'}
          onRowDoubleClick={disableRowClick ? null : onRowClick || redirectToDetails}
          onSelectAll={(e) => setSelectedAll(e)}
          onSelectionChange={(e) => setSelectedRow(e)}
          searchPlaceholder={searchCondition}
        >
          {isTagManagement ? (
            <StyledDataTable.ActionBar>
              <StyledDataTable.ActionBar.Item
                icon={<Icon root="common" name="tag" size="small" />}
                content={t('AssetManagementTable_TagManagement')}
                onClick={() => onEditDevicesTags(selectedRow.map((a) => a.details))}
              />
            </StyledDataTable.ActionBar>
          ) : null}
          {columns.map((key) => {
            if (key.field === 'sitePath') {
              return (
                <StyledDataTable.Column
                  initialWidth={key.initialWidth}
                  key={key.field}
                  field={key.field}
                  renderer={siteRenderer}
                  header={t(key.translation) || ''}
                  // sortable={key.field !== 'collapse' ? true : false}
                />
              );
            } else {
              return (
                <StyledDataTable.Column
                  initialWidth={key.initialWidth}
                  key={key.field}
                  field={key.field}
                  renderer={key.field !== 'collapse' ? key.render : expanderTemplate}
                  header={t(key.translation) || ''}
                  // sortable={key.field !== 'collapse' ? true : false}
                />
              );
            }
          })}
        </StyledDataTable>
      </div>
    </>
  );
};
export default AssetManagementTable;
