import { get } from 'lodash'

export const Keys = {
  notificationDash: 2,
  totalDevices: 2,
  neverused: 2,
  registeredDevices: 2,
  onboardedBatteries: 2,
  sitesWithDevices: 2,
  devicesSmartBatteries: 2,
  totalBatteries: 2,
  devicesByType: 3,
  devicesBySite: 3,
  devicesByModel: 3,
  offDevicesByType: 3,
  offDevicesBySite: 3,
  offlineDevicesByModel: 3,
  typesOfDisruptions: 3,
  lostAndFound: 1,
  applicationDisruptions: 1,
  applicationDisruptionsByApp: 0,
  '500Cycles': 2,
  lowHealth: 2,
  agingBatteries: 2,
  replaceSoon: 0,
  replaceNow: 0,
  lowBatteryEvents: 1,
  newBatteries: 2,
  devicesByUsageLevel: 0,
  devicesUsePerDay: 1,
  mobileOSVersion: 3,
  droppedDevices: 3,
  reboots: 0,
  securityPatch: 3,
  firmwareVersion: 3,
  fixedUsageLabels: 0,
  fixedUsageMeters: 0,
  printVolume: 1,
  mobileUsageLabels: 0,
  mobileUsageMeters: 0,
  faultyDots: 2,
  outOfMedia: 2,
  outOfRibbon: 2,
  scanVolume: 1,
  scannerFirmwareVersion: 3,
  triggerPulls: 3,
  ltlTotalTrips: 1,
  ltlMissedStops: 1,
  ltlDwellTime: 1,
  notReportingBatteries: 2,
  deletedBatteries: 2,
  applicationUsageTime: 0,
  networkIo: 0
}

export const defaultCards = {
  notificationDash: {
    key: 'notificationDash',
    isVisible: true,
    position: 0,
    columns: 12
  },
  totalDevices: {
    key: 'totalDevices',
    isVisible: true,
    position: 0,
    columns: 4
  },
  neverused: {
    key: 'neverused',
    isVisible: true,
    position: 1,
    columns: 4
  },
  registeredDevices: {
    key: 'registeredDevices',
    isVisible: true,
    position: 2,
    columns: 4
  },
  onboardedBatteries: {
    key: 'onboardedBatteries',
    isVisible: true,
    position: 3,
    columns: 4
  },
  sitesWithDevices: {
    key: 'sitesWithDevices',
    isVisible: true,
    position: 4,
    columns: 4
  },
  devicesSmartBatteries: {
    key: 'devicesSmartBatteries',
    isVisible: true,
    position: 5,
    columns: 4
  },
  devicesByType: {
    key: 'devicesByType',
    isVisible: true,
    position: 6,
    columns: 4
  },
  devicesBySite: {
    key: 'devicesBySite',
    isVisible: true,
    position: 7,
    columns: 4
  },
  devicesByModel: {
    key: 'devicesByModel',
    isVisible: true,
    position: 8,
    columns: 4
  },
  offDevicesByType: {
    key: 'offDevicesByType',
    isVisible: true,
    position: 9,
    columns: 4
  },
  offDevicesBySite: {
    key: 'offDevicesBySite',
    isVisible: true,
    position: 10,
    columns: 4
  },
  offlineDevicesByModel: {
    key: 'offlineDevicesByModel',
    isVisible: true,
    position: 11,
    columns: 4
  },
  typesOfDisruptions: {
    key: 'typesOfDisruptions',
    isVisible: true,
    position: 12,
    columns: 4
  },
  lostAndFound: {
    key: 'lostAndFound',
    isVisible: true,
    position: 13,
    columns: 4
  },
  ltlTotalTrips: {
    key: 'ltlTotalTrips',
    isVisible: true,
    position: 0,
    columns: 4
  },
  ltlMissedStops: {
    key: 'ltlMissedStops',
    isVisible: true,
    position: 1,
    columns: 4
  },
  ltlDwellTime: {
    key: 'ltlDwellTime',
    isVisible: true,
    position: 2,
    columns: 4
  },
  '500Cycles': {
    key: '500Cycles',
    isVisible: true,
    position: 0,
    columns: 4
  },
  lowHealth: {
    key: 'lowHealth',
    isVisible: true,
    position: 1,
    columns: 4
  },
  agingBatteries: {
    key: 'agingBatteries',
    isVisible: true,
    position: 2,
    columns: 4
  },
  replaceSoon: {
    key: 'replaceSoon',
    isVisible: true,
    position: 3,
    columns: 4
  },
  replaceNow: {
    key: 'replaceNow',
    isVisible: true,
    position: 4,
    columns: 4
  },
  lowBatteryEvents: {
    key: 'lowBatteryEvents',
    isVisible: true,
    position: 5,
    columns: 4
  },
  newBatteries: {
    key: 'newBatteries',
    isVisible: true,
    position: 14,
    columns: 4
  },
  notReportingBatteries: {
    key: 'notReportingBatteries',
    isVisible: true,
    position: 15,
    columns: 4,
  },
  deletedBatteries: {
    key: 'deletedBatteries',
    isVisible: true,
    position: 16,
    columns: 4,
  },
  devicesByUsageLevel: {
    key: 'devicesByUsageLevel',
    isVisible: true,
    position: 0,
    columns: 4
  },
  devicesUsePerDay: {
    key: 'devicesUsePerDay',
    isVisible: true,
    position: 1,
    columns: 4
  },
  mobileOSVersion: {
    key: 'mobileOSVersion',
    isVisible: true,
    position: 2,
    columns: 4
  },
  droppedDevices: {
    key: 'droppedDevices',
    isVisible: true,
    position: 3,
    columns: 4
  },
  reboots: {
    key: 'reboots',
    isVisible: true,
    position: 4,
    columns: 4
  },
  securityPatch: {
    key: 'securityPatch',
    isVisible: true,
    position: 5,
    columns: 4
  },
  applicationUsageTime: {
    key: 'applicationUsageTime',
    isVisible: true,
    position: 6,
    columns: 4
  },
  networkIo: {
    key: 'networkIo',
    isVisible: true,
    position: 7,
    columns: 4
  },
  applicationDisruptions: {
    key: 'applicationDisruptions',
    isVisible: true,
    position: 8,
    columns: 4
  },
  applicationDisruptionsByApp: {
    key: 'applicationDisruptionsByApp',
    isVisible: true,
    position: 9,
    columns: 4
  },
  firmwareVersion: {
    key: 'firmwareVersion',
    isVisible: true,
    position: 0,
    columns: 4
  },
  fixedUsageLabels: {
    key: 'fixedUsageLabels',
    isVisible: true,
    position: 1,
    columns: 4
  },
  fixedUsageMeters: {
    key: 'fixedUsageMeters',
    isVisible: true,
    position: 2,
    columns: 4
  },
  printVolume: {
    key: 'printVolume',
    isVisible: true,
    position: 3,
    columns: 4
  },
  mobileUsageLabels: {
    key: 'mobileUsageLabels',
    isVisible: true,
    position: 4,
    columns: 4
  },
  mobileUsageMeters: {
    key: 'mobileUsageMeters',
    isVisible: true,
    position: 5,
    columns: 4
  },
  faultyDots: {
    key: 'faultyDots',
    isVisible: true,
    position: 6,
    columns: 4
  },
  outOfMedia: {
    key: 'outOfMedia',
    isVisible: true,
    position: 7,
    columns: 4
  },
  outOfRibbon: {
    key: 'outOfRibbon',
    isVisible: true,
    position: 8,
    columns: 4
  },
  scanVolume: {
    key: 'scanVolume',
    isVisible: true,
    position: 0,
    columns: 4
  },
  scannerFirmwareVersion: {
    key: 'scannerFirmwareVersion',
    isVisible: true,
    position: 1,
    columns: 4
  },
  triggerPulls: {
    key: 'triggerPulls',
    isVisible: true,
    position: 2,
    columns: 4
  },
}

export const defaultSections = {
  alerts: {
    sectionKey: 'alerts',
    title: 'Alerts',
    isVisible: true,
    position: 0,
    acordionIsActive: false,
    cards: ['notificationDash']
  },
  deviceUsage: {
    sectionKey: 'deviceUsage',
    title: 'Device Usage & Connectivity',
    isVisible: true,
    position: 1,
    acordionIsActive: false,
    cards: [
      'totalDevices',
      'neverused',
      'onboardedBatteries',
      'registeredDevices',
      'sitesWithDevices',
      'devicesSmartBatteries',
      'devicesByType',
      'devicesBySite',
      'devicesByModel',
      'offDevicesByType',
      'offDevicesBySite',
      'offlineDevicesByModel',
      'typesOfDisruptions',
      'lostAndFound'
    ]
  },
  batteryHealth: {
    sectionKey: 'batteryHealth',
    title:'Battery Health',
    isVisible: true,
    position: 2,
    acordionIsActive: false,
    cards: [
      '500Cycles',
      'lowHealth',
      'agingBatteries',
      'replaceSoon',
      'replaceNow',
      'lowBatteryEvents',
      'newBatteries',
      'notReportingBatteries',
      'deletedBatteries'
    ]
  },
  mobileComputers: {
    sectionKey: 'mobileComputers',
    title: 'Mobile Computers',
    isVisible: true,
    position: 3,
    acordionIsActive: false,
    cards: [
      'devicesByUsageLevel',
      'devicesUsePerDay',
      'mobileOSVersion',
      'droppedDevices',
      'reboots',
      'securityPatch',
      'applicationUsageTime',
      'networkIo',
      'applicationDisruptions',
      'applicationDisruptionsByApp'
    ]
  },
  printers: {
    sectionKey: 'printers',
    title: 'Printers',
    isVisible: true,
    position: 4,
    acordionIsActive: false,
    cards: [
      'firmwareVersion',
      'fixedUsageLabels',
      'fixedUsageMeters',
      'printVolume',
      'mobileUsageLabels',
      'mobileUsageMeters',
      'faultyDots',
      'outOfMedia',
      'outOfRibbon'
    ]
  },
  scanning: {
    sectionKey: 'scanning',
    title: 'Scanning',
    isVisible: true,
    position: 5,
    acordionIsActive: false,
    cards: [
      'scanVolume',
      'scannerFirmwareVersion',
      'triggerPulls'
    ]
  },
  ltl: {
    sectionKey: 'ltl',
    title: 'Transportation & Logistics',
    isVisible: true,
    position: 6,
    acordionIsActive: false,
    cards: [
      'ltlTotalTrips',
      'ltlMissedStops',
      'ltlDwellTime'
    ]
  }
}

export const getCardsCondition = ({
  'notificationDash': {
    condition: 'notificationsDashboard'
  },
  'totalDevices': {
    condition: 'totalDevices'
  },
  'neverused': {
    condition: 'totalDevices'
  },
  'registeredDevices': {
    condition: 'totalDevices'
  },
  'onboardedBatteries': {
    condition: 'totalDevices'
  },
  'sitesWithDevices': {
    condition: 'countSitesWithDevices'
  },
  'devicesSmartBatteries': {
    condition: 'totalDevicesWithBatteries'
  },
  'devicesByType': {
    condition: 'totalDevicesType'
  },
  'devicesBySite': {
    condition: 'devicesBySite'
  },
  'devicesByModel': {
    condition: 'totalDevicesModel'
  },
  'offDevicesByType': {
    condition: 'offlineDevicesByType'
  },
  'offDevicesBySite': {
    condition: 'offlineDevicesBySite'
  },
  'offlineDevicesByModel': {
    condition: 'offlineDevicesByModel'
  },
  'typesOfDisruptions': {
    condition: 'distributionOfDisruptions'
  },
  'lostAndFound': {
    condition: 'lostAndFound'
  },
  '500Cycles': {
    condition: 'batteriesCycleCount'
  },
  'lowHealth': {
    condition: 'batteriesWithLowHealth'
  },
  'agingBatteries': {
    condition: 'agingBatteries'
  },
  'replaceSoon': {
    condition: 'batteryCycleAbove300'
  },
  'replaceNow': {
    condition: 'batteryHealthBelow50'
  },
  'lowBatteryEvents': {
    condition: 'batteryLowHealthEvents'
  },
  'newBatteries': {
    condition: 'newBatteriesCard'
  },
  'devicesByUsageLevel': {
    condition: 'devicesByUsageLevels'
  },
  'devicesUsePerDay': {
    condition: 'hardUseDevices'
  },
  'mobileOSVersion': {
    condition: 'mobileOsVersion'
  },
  'droppedDevices': {
    condition: 'droppedDevicesCard'
  },
  'reboots': {
    condition: 'reboots'
  },
  'securityPatch': {
    condition: 'devicesSecurityPatch'
  },
  'applicationUsageTime': {
    condition: 'applicationUsageTime'
  },
  'networkIo': {
    condition: 'networkIo'
  },
  'applicationDisruptions':{
    condition: 'applicationDisruptions'
  },
  'applicationDisruptionsByApp':{
    condition: 'applicationDisruptionsByApp'
  },
  'firmwareVersion': {
    condition: 'printersFirmwareVersion'
  },
  'fixedUsageLabels': {
    condition: 'printerUsageByLabels'
  },
  'fixedUsageMeters': {
    condition: 'printerUsageByOdometer'
  },
  'printVolume': {
    condition: 'printersPrintVolume'
  },
  'mobileUsageLabels': {
    condition: 'mobilePrinterUsageByLabels'
  },
  'mobileUsageMeters': {
    condition: 'mobilePrinterUsageByMeters'
  },
  'faultyDots': {
    condition: 'printersFaultyDot'
  },
  'outOfMedia': {
    condition: 'printerStatus'
  },
  'outOfRibbon': {
    condition: 'printerStatus'
  },
  'scanVolume': {
    condition: 'scanVolumeCard'
  },
  'scannerFirmwareVersion': {
    condition: 'scanFirmwareCard'
  },
  'triggerPulls': {
    condition: 'scanQualityCard'
  },
  'ltlTotalTrips': {
    condition: 'ltlTotalTrips'
  },
  'ltlMissedStops': {
    condition: 'ltlMissedStops'
  },
  'ltlDwellTime': {
    condition: 'ltlDwellTime'
  },
  'notReportingBatteries': {
    condition: 'nonReportingBatteries'
  },
  'deletedBatteries': {
    condition: 'deletedBatteriesCard'
  }
})

export const GetCardTypeByKey = key => {
  return get(Keys, key, 0)
}

export const defaultChartData = {
  series: [
    { key: 'Corvettes (CR90)', value: 8 },
    { key: 'Frigates (DP20)', value: 4 },
    { key: 'Medium Transports (GR-75)', value: 16 },
    { key: 'Star Cruisers (MC80)', value: 13 },
    { key: 'Millennium Falcon', value: 1 }
  ],
  total: 5
}
