import { createStructuredSelector, createSelector } from 'reselect'
import { get, find } from 'lodash'

export const avgBatteryCycleCard = state => state.dashboard.batteriesLowHealthCard

export const dataResponse = createSelector(
  [avgBatteryCycleCard],
  avgBatHp => avgBatHp.getIn(['batteryList'], [])
)

export const sites = ({ siteManager }) => siteManager.getIn(['rootSitesById'], [])


export const data = createSelector(
  dataResponse,
  sites,
  (dataResponse, sites) => dataResponse.map(data => ({
      site: get(find(sites, { value: data.deviceSite }), 'text', ' '),
      serialNumber: data.serialNumber,
      currentDevice: data.currentDevice,
      currentDeviceAlias: data.currentDeviceAlias,
      batteryPercentage: data.batteryPercentage
    }))
)

export default createStructuredSelector({
  data
})
