import { createStructuredSelector, createSelector } from 'reselect'
import { momentTimeYear } from '../../../../Utils/DateLocalizationHelper'

export const avgBatteryCycleCard = state => state.dashboard.batteryHealthBelow

export const currentCulture = state => state.user.language

export const data = createSelector(
  [avgBatteryCycleCard, currentCulture],
  (avgBatHp, culture) => avgBatHp.getIn(['batteryList'], []).map((row, index) => {
    const hour = momentTimeYear(row.calculatedManufactureDate, culture.slice(0, 2))
    return {
      serialNumber: row.serialNumber,
      batteryAge: row.batteryAge,
      batteryPercentage: row.batteryPercentage,
      cycleCount: row.cycleCount,
      currentDevice: row.currentDevice,
      currentDeviceAlias: row.currentDeviceAlias,
      calculatedManufactureDate: hour,
      deviceSite: row.deviceSite
    }
  })
)

export default createStructuredSelector({
  data
})
