import get from 'lodash/get';

import moment from 'moment';
import { isValidJsonString } from 'Utils/PropertyHelper';

// Round relative time evaluation down
moment.relativeTimeRounding(Math.floor);

let onPremVariables = undefined;
const onPrem = global.localStorage.getItem('__OP_INTEL__');
if (onPrem !== undefined && isValidJsonString(onPrem)) onPremVariables = JSON.parse(onPrem);

const config = {
  debug: process.env.REACT_APP_DEBUG_MODE === 'true',
  powerBi: {
    siteName: process.env.REACT_APP_SITE_NAME,
    siteId: process.env.REACT_APP_SITE_ID,
    deviceTypeId: process.env.REACT_APP_DEVICE_TYPE_ID,
    deviceTypeName: process.env.REACT_APP_DEVICE_TYPE_NAME,
  },
  site: {
    safeUrl: get(onPremVariables, 'url.safeUrl', `${process.env.REACT_APP_SITE_SAFE}`),
    home: `${process.env.REACT_APP_SITE_SAFE}${process.env.REACT_APP_LANDING_PAGE}`,
    landing: process.env.REACT_APP_LANDING_PAGE,
  },
  api: {
    connectedaidc: get(onPremVariables, 'url.api', process.env.REACT_APP_API_URL),
    authUrl: get(onPremVariables, 'url.authUrl', process.env.REACT_APP_AUTH_URL),
    logOut: get(onPremVariables, 'url.logoutUrl', process.env.REACT_APP_LOGOUT_URL),
    apiKey: process.env.REACT_APP_APIGEE_KEY,
    epUrl: get(onPremVariables, 'url.provisioner', process.env.REACT_APP_EP_URL),
    remoteAccessUrl: get(onPremVariables, 'url.remote', process.env.REACT_APP_RA_URL),
    lmURL: process.env.REACT_APP_LM_URL,
    lmToolsURL: process.env.REACT_APP_LM_TOOLS_URL,
    privacyUrl: process.env.REACT_APP_PRIVACY_URL,
    openToolsBaseUrl: process.env.REACT_APP_OPEN_TOOLS_BASE_URL,
  },
  auth: {
    oauth: {
      redirect_uri: get(onPremVariables, 'url.oauthcallback', process.env.REACT_APP_OAUTH_CALLBACK),
    },
    forge: {
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: get(onPremVariables, 'url.base', process.env.REACT_APP_SITE_URL),
      response_mode: 'form_get',
      response_type: 'token id_token',
      scope: 'openid profile',
      decision: 'allow',
      realm: process.env.REACT_APP_REALM,
      nonce: 'aaSaaSaaSa',
      selfService: true,
    },
    google: {
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: get(onPremVariables, 'url.base', process.env.REACT_APP_SITE_URL),
      response_type: 'token id_token',
      scope: 'openid profile email',
      nonce: 'aaSaaSaaSa',
      prompt: 'select_account',
    },
  },
  isTcoAvailable: process.env.REACT_APP_TCO_REPORT === 'true',
  isLocationAvailable: process.env.REACT_APP_LOCATION === 'true',
  isTranslationAvailable: process.env.REACT_APP_TRANSLATION === 'true',
  launchDarkly: {
    clientId: process.env.REACT_APP_LAUNCH_DARKLY_CLIENTID,
    clientType: get(onPremVariables, 'launch_darkly_clientType', process.env.REACT_APP_LAUNCH_DARKLY_CLIENTTYPE),
  },
  login: {
    allowGoogle: get(onPremVariables, 'login.google', process.env.REACT_APP_LOGIN_GOOGLE === 'true'),
    allowSaml: get(onPremVariables, 'login.saml', process.env.REACT_APP_LOGIN_SAML === 'true'),
    allowForge: get(onPremVariables, 'login.forge', process.env.REACT_APP_LOGIN_FORGE === 'true'),
    redirectDefault: get(onPremVariables, 'login.redirectDefault', process.env.REACT_APP_LOGIN_REDIRECT_DEFAULT),
  },
  tenant: {
    qrData: get(onPremVariables, 'tenant.qrData', false),
  },
  agentMinVersion: process.env.REACT_APP_AGENT_MIN_VERSION,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  appVersion: process.env.REACT_APP_VERSION ?? '1.0.0-rc',
  appTitle: 'Honeywell | Operational Intelligence',
  bingKey: get(window, '__OP_INTEL__.keys.bingKey') || process.env.REACT_APP_BING_KEY,
  isLatencyTrackingEnabled:
    get(window, '__OP_INTEL__.latencyTrackingEnabled') || process.env.REACT_APP_LATENCY_TRACKING_ENABLED === 'true',
  latencyTrackingKey: get(window, '__OP_INTEL__.keys.latencyTrackingKey') || process.env.REACT_APP_LATENCY_TRACKING_KEY,
  techSupportRoleId: process.env.REACT_APP_TECH_SUPPORT_ROLEID ?? '601d988824c63261e40adcf0',
  azureMapsKey: process.env.REACT_APP_AZURE_KEY,
  cookiesSdkUrl: process.env.REACT_APP_SDK_URL,
  cookiesAutoBlockUrl: process.env.REACT_APP_AUTOBLOCK_URL,
  cookiesSdkKey: process.env.REACT_APP_SDK_KEY,
};

export default config;
