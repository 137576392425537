import React, { useState } from 'react'
import {
  Select,
  Radio,
  Checkbox,
  Input,
  Icon
} from '@scuf/common'
import styled from 'styled-components'
import get from 'lodash/get'
import { useTranslate } from 'react-translate'
import { Badge } from "@scuf/common";

import DataTable from '../../../DataTable'
import { useDeviceModels } from './filters.hook'
import { useSites } from 'Hooks/sites.hook'
import FilterTags from '../filter-tags'
import * as Constants from '../state-modal/state-modal.constants'
import { translate } from 'react-translate'
import {
  FilterContainer,
  OptionsContainer,
  RadioContainer,
  CheckboxContainer,
  FilterBadge
} from './filters.styles'

export const StyledSelect = styled(Select)`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-right: ${props => !props.noPadding && props.theme.padding};
  .delete.icon {
    z-index: 200;
  }
  .chevron.icon {
    position: absolute;
    right: 0.5rem;
  }
  .ui.fluid.dropdown {
    display: block !important;
  }
`

const Filters = ({
  resetAllFilters,
  pushParamToUrl,
  params,
  assetType,
  flags,
  canTagRead,
  stickyFilter,
  t
}) => {
  const connectionStatusTranslate = useTranslate('ConnectionStatus')
  const deviceDbStatusTranslate = useTranslate('DeviceDbStatus')
  const sites = useSites()
  const { models } = useDeviceModels(assetType)
  const modelsAsKey = get(params, 'model', []).join(':')
  const sitesAsKey = get(params, 'sites', []).join(':')
  const pushTagsToUrl = selectedTags => {
    pushParamToUrl('tags', selectedTags())
  }

  const [ipStart, SetIPStart] = useState(params.ipStart !== undefined ? params.ipStart : '')
  const [ipEnd, SetIPEnd] = useState(params.ipEnd !== undefined ? params.ipEnd : '')
  const [isCheckedIpNotAvaible, SetIsCheckedIpNotAvaible] = useState(params.ipNotAvailable !== undefined)
  const [ipStartError, SetIPStartError] = useState('')
  const [ipEndError, SetIPEndError] = useState('')
  const isLicensingEnabled = flags.licensing
  const onChangeIPStart = (e) => {
    const ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    if (ipAddressRegex.test(e)) {
      pushParamToUrl('ipStart', e)
      SetIPStartError('')
    } else if (params.ipStart !== undefined) {
      pushParamToUrl('ipStart', undefined)
      SetIPStartError(_t('InvalidIPAddress'))
    } else if (e === '') {
      SetIPStartError('')
    } else {
      SetIPStartError(t('InvalidIPAddress'))
    }
    SetIPStart(e)
  }

  const iconIPStartClick = () => {
    pushParamToUrl('ipStart', undefined)
    SetIPStart('')
    SetIPStartError('')
  }

  const onChangeIPEnd = (e) => {
    const ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    if (ipAddressRegex.test(e)) {
      pushParamToUrl('ipEnd', e)
      SetIPEndError('')
    } else if (params.ipEnd !== undefined) {
      pushParamToUrl('ipEnd', undefined)
      SetIPEndError(t('InvalidIPAddress'))
    } else if (e === '') {
      SetIPEndError('')
    } else {
      SetIPEndError(t('InvalidIPAddress'))
    }
    SetIPEnd(e)
  }

  const iconIPEndClick = () => {
    pushParamToUrl('ipEnd', undefined)
    SetIPEnd('')
    SetIPEndError('')
  }

  const onChangeIPNotAvailable = (checked) => {
    if (checked) {
      pushParamToUrl('ipNotAvailable', checked)
    } else {
      pushParamToUrl('ipNotAvailable', undefined)
    }
    SetIsCheckedIpNotAvaible(checked)
    onChangeIPStart('')
    onChangeIPEnd('')
  }


  return (
    <DataTable.FilterPanel
      count={1}
      onReset={resetAllFilters}
    >
      {true &&
        <FilterContainer>
          {params.deviceCheckStatus !== undefined && <FilterBadge color="blue" />}
          <DataTable.FilterPanel.Section label={connectionStatusTranslate('status')}>
            <OptionsContainer>
              <RadioContainer>
                <Radio
                  checked={params.deviceCheckStatus === 'checkedin'}
                  label={connectionStatusTranslate('checkedIn')}
                  onChange={() => pushParamToUrl('deviceCheckStatus', 'checkedin')}
                />
              </RadioContainer>
              <RadioContainer>
                <Radio
                  checked={params.deviceCheckStatus === 'checkedout'}
                  label={connectionStatusTranslate('checkedOut')}
                  onChange={() => pushParamToUrl('deviceCheckStatus', 'checkedout')}
                />
              </RadioContainer>
              <RadioContainer>
                <Radio
                  checked={params.deviceCheckStatus === ''}
                  label={connectionStatusTranslate('available')}
                  onChange={() => pushParamToUrl('deviceCheckStatus', '')}
                />
              </RadioContainer>
              <RadioContainer>
                <Radio
                  checked={params.deviceCheckStatus === undefined}
                  label={connectionStatusTranslate('All')}
                  onChange={() => pushParamToUrl('deviceCheckStatus', undefined)}
                />
              </RadioContainer>
            </OptionsContainer>
          </DataTable.FilterPanel.Section>
        </FilterContainer>
      }
      <FilterContainer>
        {params.status && <FilterBadge color="blue" />}
        <DataTable.FilterPanel.Section label={connectionStatusTranslate('connectionStatus')}>
          <OptionsContainer>
            <RadioContainer>
              <Radio
                checked={params.status === 'connected'}
                label={connectionStatusTranslate('Connected')}
                onChange={() => pushParamToUrl('status', 'connected')}
              />
            </RadioContainer>
            <RadioContainer>
              <Radio
                checked={params.status === 'disconnected'}
                label={connectionStatusTranslate('Disconnected')}
                onChange={() => pushParamToUrl('status', 'disconnected')}
              />
            </RadioContainer>
            <RadioContainer>
              <Radio
                checked={params.status === 'deprovisioning'}
                label={connectionStatusTranslate('Deprovisioning')}
                onChange={() => pushParamToUrl('status', 'deprovisioning')}
              />
            </RadioContainer>
            <RadioContainer>
              <Radio
                checked={params.status === 'deregistering'}
                label={connectionStatusTranslate('Deregistering')}
                onChange={() => pushParamToUrl('status', 'deregistering')}
              />
            </RadioContainer>
            <RadioContainer>
              <Radio
                checked={params.status === 'deregistered'}
                label={connectionStatusTranslate('Deregistered')}
                onChange={() => pushParamToUrl('status', 'deregistered')}
              />
            </RadioContainer>
            <RadioContainer>
              <Radio
                checked={params.status === 'not connected'}
                label={connectionStatusTranslate('Provisioned')}
                onChange={() => pushParamToUrl('status', 'not connected')}
              />
            </RadioContainer>
            <RadioContainer>
              <Radio
                checked={params.status === 'unknown'}
                label={connectionStatusTranslate('Unknown')}
                onChange={() => pushParamToUrl('status', 'unknown')}
              />
            </RadioContainer>
            <RadioContainer>
              <Radio
                checked={!params.status}
                label={connectionStatusTranslate('All')}
                onChange={() => pushParamToUrl('status', undefined)}
              />
            </RadioContainer>
          </OptionsContainer>
        </DataTable.FilterPanel.Section>
      </FilterContainer>
      { (assetType == undefined || assetType == 'mobilecomputer') && isLicensingEnabled && 
        <FilterContainer>
          {params.deviceDbStatus && <FilterBadge color="blue" />}
          <DataTable.FilterPanel.Section label={deviceDbStatusTranslate('License Status')}>
            <OptionsContainer>
              <RadioContainer>
                <Radio
                  checked={params.deviceDbStatus === 'active'}
                  label={deviceDbStatusTranslate('Licensed')}
                  onChange={() => pushParamToUrl('deviceDbStatus', 'active')}
                />
              </RadioContainer>
              <RadioContainer>
                <Radio
                  checked={params.deviceDbStatus === 'unlicensed'}
                  label={deviceDbStatusTranslate('Unlicensed')}
                  onChange={() => pushParamToUrl('deviceDbStatus', 'unlicensed')}
                />
              </RadioContainer>
            </OptionsContainer>
          </DataTable.FilterPanel.Section>
        </FilterContainer>
      }
      {flags.stateManagement &&
        <FilterContainer>
          {params.deviceState && <FilterBadge color="blue" />}
          <DataTable.FilterPanel.Section label={connectionStatusTranslate('assetState')}>
            <OptionsContainer>
              <RadioContainer>
                <Radio
                  checked={params.deviceState === Constants.ACTIVE}
                  label={connectionStatusTranslate('active')}
                  onChange={() => pushParamToUrl('deviceState', Constants.ACTIVE)}
                />
              </RadioContainer>
              <RadioContainer>
                <Radio
                  checked={params.deviceState === Constants.LOST}
                  label={connectionStatusTranslate('lost')}
                  onChange={() => pushParamToUrl('deviceState', Constants.LOST)}
                />
              </RadioContainer>
              <RadioContainer>
                <Radio
                  checked={params.deviceState === Constants.OUT_FOR_REPAIR}
                  label={connectionStatusTranslate('outForRepair')}
                  onChange={() => pushParamToUrl('deviceState', Constants.OUT_FOR_REPAIR)}
                />
              </RadioContainer>
              <RadioContainer>
                <Radio
                  checked={params.deviceState === Constants.OUT_FOR_SERVICE}
                  label={connectionStatusTranslate('outForService')}
                  onChange={() => pushParamToUrl('deviceState', Constants.OUT_FOR_SERVICE)}
                />
              </RadioContainer>
              <RadioContainer>
                <Radio
                  checked={!params.deviceState}
                  label={connectionStatusTranslate('All')}
                  onChange={() => pushParamToUrl('deviceState', undefined)}
                />
              </RadioContainer>
            </OptionsContainer>
          </DataTable.FilterPanel.Section>
        </FilterContainer>
      }
      <FilterContainer>
        {params.model && <FilterBadge color="blue" />}
        <DataTable.FilterPanel.Section label={connectionStatusTranslate('model')}>
          <StyledSelect
            fluid
            key={modelsAsKey}
            multiple
            onChange={model => pushParamToUrl('model', model)}
            options={models}
            search={true}
            value={params.model}
          />
        </DataTable.FilterPanel.Section>
      </FilterContainer>
      <FilterContainer>
        {params.sites && <FilterBadge color="blue" />}
        <DataTable.FilterPanel.Section label={connectionStatusTranslate('sites')}>
          <StyledSelect
            fluid
            key={sitesAsKey}
            multiple
            onChange={selectedSites => pushParamToUrl('sites', selectedSites)}
            options={sites}
            search={true}
            value={params.sites}
          />
          <CheckboxContainer>
            <Checkbox
              checked={!!params.includeChildSites}
              disabled={!params.sites}
              label={connectionStatusTranslate('checkSites')}
              onChange={(checked) => pushParamToUrl('includeChildSites', checked || undefined)}
            />
          </CheckboxContainer>
        </DataTable.FilterPanel.Section>
      </FilterContainer>
      {flags.assetTagsFeature && canTagRead &&
        <FilterContainer>
          {params.tags && <FilterBadge color="blue" />}
          <DataTable.FilterPanel.Section label={connectionStatusTranslate('tags')}>
            <FilterTags
              setSelectedTags={pushTagsToUrl}
              paramTags={params.tags}
            />
          </DataTable.FilterPanel.Section>
        </FilterContainer>
      }
      <FilterContainer>
        {params.ipStart !== undefined && params.ipEnd !== undefined && <FilterBadge color="blue" />}
        <DataTable.FilterPanel.Section label={connectionStatusTranslate('ipAddress')}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Input
              disabled={params.ipNotAvailable !== undefined}
              placeholder='Ex. 127.0.0.0'
              label={connectionStatusTranslate('ipStart')}
              value={ipStart}
              icon={<Icon name='close' root='common' size='small' onClick={iconIPStartClick} disabled={params.ipStart === undefined} />}
              onChange={onChangeIPStart}
              error={ipStartError}
              reserveSpace={false}
            />
            <Input
              disabled={params.ipNotAvailable !== undefined}
              placeholder='Ex. 127.0.0.255'
              label={connectionStatusTranslate('ipEnd')}
              value={ipEnd}
              icon={<Icon name='close' root='common' size='small' onClick={iconIPEndClick} disabled={params.ipEnd === undefined} />}
              onChange={onChangeIPEnd}
              error={ipEndError}
              reserveSpace={false}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Checkbox
              disabled={params.ipStart !== undefined || params.ipEnd !== undefined}
              checked={isCheckedIpNotAvaible}
              label={connectionStatusTranslate('assetsNoIp')}
              onChange={onChangeIPNotAvailable}
            />
          </div>
        </DataTable.FilterPanel.Section>
      </FilterContainer>
    </DataTable.FilterPanel>
  )
}

export default Filters
