import React from 'react'
import { Input } from '@scuf/common'
import { translate } from 'react-translate'
import { errorTranslation } from './utils'

export const FormInput = ({
  t,
  className,
  placeholder,
  input,
  type,
  stretch,
  disabled,
  min,
  max,
  label,
  indicator,
  autoComplete,
  meta: {
    error,
    touched,
    submitFailed
  }
}) => {
  return (
    <Input
      className={className || ''}
      fluid={!stretch}
      disabled={disabled}
      label={label}
      indicator={indicator}
      placeholder={placeholder}
      min={min}
      max={max}
      type={type}
      autoComplete={autoComplete}
      {...input}
      {...(touched || submitFailed) && error
        ? { error: errorTranslation(t, error) }
        : {}
      }
    />
  )
}

export default translate('FormInput')(FormInput)
