import { siteId } from "Components/DashboardCards/OfflineDevicesByType/offline-devices-by-type.selector"

const create = (api) => {
  const getAvgBatteryAgeCard = (siteId, params) => api.get(`/dashboard/batteryhealth/age/${siteId}`, params)
  const getDeviceUsageLevel = (siteId, params) => api.get(`/dashboard/deviceutilization/hoursofuse/${siteId}`, params)
  const getPrinterUsageLevelByMeters = (siteId, params) => api.get(`/dashboard/printer/meters/${siteId}`, params)
  const getMobilePrinterUsageLevelByMeters = (siteId, params) => api.get(`/dashboard/printer/meters/${siteId}`, params)
  const getScanPullsCard = (siteId, params) => api.get(`/dashboard/disruptions/failedscanpulls/${siteId}`, params)
  const getPrintersOutOfPaperCard = (siteId, params) => api.get(`/dashboard/disruptions/outmedia/${siteId}`, params)
  const getPrintersOutOfRibbonCard = (siteId, params) => api.get(`/dashboard/disruptions/outribbon/${siteId}`, params)
  const getAvgBatteryCycleCountCard = (siteId, params) => api.get(`/dashboard/batteryhealth/cyclecount/${siteId}`, params)
  const getPtcDevicesUseCard = (siteId, params) => api.get(`/dashboard/deviceutilization/percentageuse/${siteId}`, params)
  const getHardRebootsCard = (siteId, params) => api.get(`/dashboard/deviceutilization/devicereboots/${siteId}`, params)
  const getBatterySwapsCard = (siteId, params) => api.get(`/dashboard/deviceutilization/batteryswaps/${siteId}`, params)
  const getAlerts = (siteId, params) => api.get(`alerts/${siteId}`, params)
  const getPrinterUsageLevelByLabels = (siteId, params) => api.get(`/dashboard/printer/labels/${siteId}`, params)
  const getMobilePrinterUsageLevelByLabels = (siteId, params) => api.get(`/dashboard/printer/labels/${siteId}`, params)
  const getAgingBatteriesCard = (siteId, params) => api.get(`/dashboard/batteryhealth/aging/${siteId}`, params)
  const getBatteriesLowHealthCard = (siteId, params) => api.get(`/dashboard/batteryhealth/lowhealth/${siteId}`, params)
  const getDistributionOfDipsCard = (siteId, params) => api.get(`/dashboard/disruptions/distributiondips/${siteId}`, params)
  const getTotalDevicesCard = (siteId, params) => api.get(`/v2/deviceutilization/totaldevicesstatus/${siteId}`, params)
  const getFixedDevicesOfflineCard = (siteId, params) => api.get(`/dashboard/deviceutilization/offlinedevices/${siteId}`, params)
  const getBatteriesCycleAboveCard = (siteId, params) => api.get(`/dashboard/batteryhealth/cyclecount/${siteId}`, params)
  const getBatteriesHealthBelowCard = (siteId, params) => api.get(`/dashboard/batteryhealth/lowhealth/${siteId}`, params)
  const getBatteriesHealthBetweenCard = (siteId, params) => api.get(`/dashboard/batteryhealth/lowhealth/${siteId}`, params)
  const getFirmwareCard = (siteId, params) => api.get(`/dashboard/deviceutilization/firmwareversion/${siteId}`, params)
  const getScannerFirmwareCard = (siteId, params) => api.get(`/dashboard/deviceutilization/firmwareversion/${siteId}`, params)
  const getOsCard = (siteId, params) => api.get(`/dashboard/deviceutilization/firmwareversion/${siteId}`, params)
  const getBatteriesLowHealthEventsCard = (siteId, params) => api.get(`/dashboard/batteryhealth/lowbattery/${siteId}`, params)
  const getTotalBatteries = (siteId, params) => api.get(`/dashboard/deviceutilization/totalbatteries/${siteId}`, params)
  const getBatteryHealth = (type, siteId, params) => api.get(`/dashboard/batteryhealth/${type}/${siteId}`, params)
  const getTotalDevicesStatus = (siteId, params) => api.get(`/v2/deviceutilization/totaldevicesstatus/${siteId}`, params)
  const getTotalSmartBatteries = (siteId, params) => api.get(`/dashboard/deviceutilization/totaldevices/${siteId}`, params)
  const getAvgBatteryChargeCard = (siteId, params) => api.get(`/dashboard/batteryhealth/level/${siteId}`, params)
  const getBatteryStatsCard = (siteId, params) => api.get(`/dashboard/batteryhealth/combined/${siteId}`, params)
  const getDroppedDevicesCard = (siteId, params) => api.get(`/dashboard/disruptions/devicetypesofdrops/${siteId}`, params)
  const getDeviceLocationHistory = (deviceId, params) => api.get(`/dashboard/sensor-points/${deviceId}`, params)
  const getScanVolumeCard = (siteId, params) => api.get(`/dashboard/deviceutilization/scanvolume/${siteId}`, params)
  const getPrintVolumeCard = (siteId, params) => api.get(`/dashboard/deviceutilization/printvolume/${siteId}`, params)
  const getPrintersFaultyDots = (siteId, params) => api.get(`/dashboard/disruptions/printersfaulty/${siteId}`, params)
  const getHardUseDevicesCard = (siteId, params) => api.get(`/dashboard/deviceutilization/dailyuse/${siteId}`, params)
  const getHoursOfUseCard = (siteId, params) => api.get(`/dashboard/deviceutilization/hoursofuse/${siteId}`, params)
  const getDeviceTrendByPoint = (deviceId, count, pointName) => api.get(`/dashboard/sensor-points/${deviceId}`, {
    count: count,
    fields: pointName
  })
  const getDeviceTrendByPoints = (deviceId, count, startDate, endDate, pointNames) => api.get(`/dashboard/v2/sensor-points/${deviceId}`, {
    count: count,
    startDate: startDate,
    endDate: endDate,
    fields: pointNames.join()
  })
  const getDeviceDeepSleepAndBatteryVoltageList = (deviceId, startDate, endDate, siteId) => api.get(`/dashboard/rfidreader/deepsleepbatteryvoltagelist/${siteId}`, {
    startDate: startDate,
    endDate: endDate,
    serialNumber: deviceId
  })
  const getDeviceErrorList = (deviceId, startDate, endDate, siteId) => api.get(`/dashboard/rfidreader/errorlist/${siteId}`, {
    startDate: startDate,
    endDate: endDate,
    serialNumber: deviceId
  })
  const getDeviceInventoryCycleList = (deviceId, startDate, endDate) => api.get(`/rfidreader/inventorycyclelist`, {
    startDate: startDate,
    endDate: endDate,
    serialNumber: deviceId
  })
  const getSecurityPatch = (siteId, params) => api.get(`/dashboard/deviceutilization/securitypatchversion/${siteId}`, params)
  const getPrintedMetersByDevice = (siteId, params) => api.get(`/dashboard/printer/devicemeters/${siteId}`, params)
  const getNewBatteriesLastMonthCard = (siteId, params) => api.get(`/dashboard/batteryhealth/newbatteries/${siteId}`, params)
  const getDashboardEventsByName = (siteId, params) => api.get(`/dashboard/disruptions/events/${siteId}`, params)

  const getExecDbData = () => api.get(`/dashboard/aggregator/overalldata`)
  const getSpecificOrgExecData = (orgIds) => {
    const filter = { orgIds: { $in: orgIds } }
    return api.get(`/dashboard/aggregator/overalldata?filter=${JSON.stringify(filter)}`)
  }
  const getExecDbSecondaryData = () => api.get(`/dashboard/aggregator/instrumentation-data`)
  const getTotalBatteriesDetails = (siteId, params) => api.get(`/dashboard/deviceutilization/totalbatteriesdetails/${siteId}`, params)
  const getNotReportingBatteriesDetails = (siteId, params) => api.get(`/dashboard/deviceutilization/totalbatteriesdetails/${siteId}`, params)
  const deleteBatteries = (batteryList, organizationId) => api.put(`/dashboard/batteryhealth/removed`, { organizationId, batteryInfo: batteryList })
  const addBatteries = (batteryList, organizationId) => api.put(`/dashboard/batteryhealth/recover`, { organizationId, batteryInfo: batteryList })
  const getDeletedBatteriesCard = (siteId, params) => api.get(`/dashboard/batteryhealth/removed/${siteId}`, params)
  const getApplicationUsageTime = (payload) => api.get(`/dashboard/v1/stats/applications:${payload}`)
  const getExportCVSFile = (siteId, payload, params) =>
    api.post(`/dashboard/exportfiles/${siteId}`, payload, { params });
  const getLostAndFoundCard = (siteId, params) => api.get(`/dashboard/lostdevices/${siteId}`, params)
  const getApplicationDisruptionsCard = (siteId, params) => api.get(`/dashboard/v1/mobilecomputer/applications/disruptions/${siteId}`, params)
  const getApplicationDisruptionsDetails = (siteId, params) => api.get(`/dashboard/v1/mobilecomputer/applications/disruptions/${siteId}/details`, params)
  const getApplicationDisruptionsCardByDevice = (siteId, params) => api.get(`/dashboard/v1/mobilecomputer/applications/disruptions/${siteId}`, params)
  const getApplicationDisruptionsByAppCard = (siteId, params) => api.get(`/dashboard/v1/mobilecomputer/applications/disruptions/${siteId}/top-applications`, params)
  const getApplicationDisruptionsByAppDetails = (siteId, params) => api.get(`/dashboard/v1/mobilecomputer/applications/disruptions/${siteId}/top-applications/details`, params)

  return {
    getAvgBatteryAgeCard,
    getAvgBatteryCycleCountCard,
    getDeviceUsageLevel,
    getPrinterUsageLevelByMeters,
    getMobilePrinterUsageLevelByMeters,
    getScanPullsCard,
    getPrintersOutOfPaperCard,
    getPrintersOutOfRibbonCard,
    getDashboardEventsByName,
    getPtcDevicesUseCard,
    getHardRebootsCard,
    getBatterySwapsCard,
    getAlerts,
    getPrinterUsageLevelByLabels,
    getMobilePrinterUsageLevelByLabels,
    getAgingBatteriesCard,
    getBatteriesLowHealthCard,
    getDistributionOfDipsCard,
    getTotalDevicesCard,
    getFixedDevicesOfflineCard,
    getBatteriesCycleAboveCard,
    getBatteriesHealthBelowCard,
    getBatteriesHealthBetweenCard,
    getFirmwareCard,
    getOsCard,
    getTotalBatteries,
    getScannerFirmwareCard,
    getBatteriesLowHealthEventsCard,
    getBatteryHealth,
    getTotalDevicesStatus,
    getDroppedDevicesCard,
    getDeviceLocationHistory,
    getAvgBatteryChargeCard,
    getBatteryStatsCard,
    getPrintersFaultyDots,
    getScanVolumeCard,
    getPrintVolumeCard,
    getHardUseDevicesCard,
    getHoursOfUseCard,
    getDeviceTrendByPoint,
    getDeviceTrendByPoints,
    getDeviceDeepSleepAndBatteryVoltageList,
    getDeviceErrorList,
    getDeviceInventoryCycleList,
    getSecurityPatch,
    getPrintedMetersByDevice,
    getNewBatteriesLastMonthCard,
    getTotalSmartBatteries,
    getExecDbData,
    getSpecificOrgExecData,
    getExecDbSecondaryData,
    getTotalBatteriesDetails,
    getNotReportingBatteriesDetails,
    deleteBatteries,
    addBatteries,
    getDeletedBatteriesCard,
    getApplicationUsageTime,
    getExportCVSFile,
    getLostAndFoundCard,
    getApplicationDisruptionsCard,
    getApplicationDisruptionsDetails,
    getApplicationDisruptionsCardByDevice,
    getApplicationDisruptionsByAppCard,
    getApplicationDisruptionsByAppDetails
  }
}

export default {
  create
}
