import moment from 'moment'

export const momentTimeHour = (date, locale) => {
  let format = moment.localeData().longDateFormat('L')
  // Remove year part
  format = format.replace(/.YYYY/, '')
  switch (locale) {
    case 'es':
      format = 'hh:mm DD/MM'
      break
    default:
      format = 'hh:mm MM/DD'
      break
  }
  return moment(date).utc().format(format)
}

export const momentTimeDayMonthLetterYear = (date, locale) => {
  let format = moment.localeData().longDateFormat('L')
  switch (locale) {
    case 'es':
      format = 'DD MMM YYYY'
      break
    default:
      format = 'MMM DD YYYY'
      break
  }
  return moment(date).utc().format(format)
}

export const momentTime = (date, locale) => {
  let format = moment.localeData().longDateFormat('L')
  // Remove year part
  format = format.replace(/.YYYY/, '')
  switch (locale) {
    case 'es':
      format = 'DD/MM'
      break
    default:
      format = 'MM/DD'
      break
  }
  return moment(date).utc().format(format)
}

export const momentTimeYear = (date, locale) => {
  let format = moment.localeData().longDateFormat('L')
  // Remove year part
  switch (locale) {
    case 'es':
      format = 'DD/MM/YYYY'
      break
    default:
      format = 'MM/DD/YYYY'
      break
  }
  return moment(date).utc().format(format)
}

export const momentTimeYearHour = (date, locale, utc = false) => {
  let format = moment.localeData().longDateFormat('L')
  // Remove year part
  switch (locale) {
    case 'es':
      format = 'DD/MM/YYYY HH:mm:ss'
      break
    default:
      format = 'MM/DD/YYYY HH:mm:ss'
      break
  }
  return utc ? moment.utc(date).format(format) : moment(date).format(format)
}
