import { createStructuredSelector, createSelector } from 'reselect'
import { momentTimeYear } from '../../../../Utils/DateLocalizationHelper'

export const avgBatteryCycleCard = state => state.dashboard.batteryAbove

export const currentCulture = state => state.user.language

export const data = createSelector(
  [avgBatteryCycleCard, currentCulture],
  (avgBatHp, culture) => avgBatHp.getIn(['batteryList'], []).filter(
    value => value.batteryPercentage >= 50 && value.cycleCount <= 500 && (value.batteryAge == null || value.batteryAge == undefined ||  value.batteryAge <= 30)
  ).map((row, index) => {
    const hour = momentTimeYear(row.calculatedManufactureDate, culture.slice(0, 2))
    return {
      serialNumber: row.serialNumber,
      batteryAge: row.batteryAge,
      batteryPercentage: row.batteryPercentage,
      cycleCount: row.cycleCount,
      currentDevice: row.currentDevice,
      currentDeviceAlias: row.currentDeviceAlias,
      calculatedManufactureDate: hour,
      deviceSite: row.deviceSite
    }
  })
)

export const loading = createSelector(
  [avgBatteryCycleCard],
  avgBatHp => avgBatHp.getIn(['loading'], false)
)

export default createStructuredSelector({
  data,
  loading
})
