import { HeaderText } from 'Components/DataTable/data-table.styles';

const headerLabelRenderer = ({
  label = "",
}) => {

  const children = [
    <HeaderText
      key="label"
      title={typeof label === 'string' ? label : null}>
      {label}
    </HeaderText>,
  ];

  return children
}

export default headerLabelRenderer
