import { Notification } from '@scuf/common'
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces'
import moment from 'moment'
import * as React from 'react'
import { Field } from 'redux-form'
import styled from 'styled-components/macro'

import ActionBar from '../Components/ActionBar'
import { RowItem } from '../Components/DataTable/data-table.styles'

export const Link = styled.span`
  height: 22px;
  width: 349px;
  color: #1274B7;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 22px;
`

const ToastNotification = styled(Notification)`
  &.ui.notification {
    margin: 0 !important;
    background-color: ${({ theme }) => theme?.colors?.componentBackground ?? '#ffffff'};

    .content .message {
      color: ${({ theme }) => theme?.colors?.textBase ?? '#303030'};
    }
  }
  &.ui.notification.important {
    border-color: ${(props) => props?.color ? `${props.color} !important` : ''};
  }
  &.ui.notification.important .icon .h-icon {
    color: ${(props) => props?.color ? `${props.color} !important` : ''};
  }
  z-index: 1;
`

export interface ToastProps {
  closeToast?: () => void
  message: string | React.ReactNode
  title?: string
  color?: string
  detailsText?: string
  detailsClick?: () => void
}

const SuccessToast: React.FC<ToastProps> = ({ closeToast, message, title, detailsText, detailsClick }) => (
  <ToastNotification
    hasIcon={true}
    severity='success'
    onCloseClick={closeToast}
    title={title}
    onDetailsClick={detailsClick}
    detailsText={detailsText}
  >
    {message || 'Request Accepted'}
  </ToastNotification>
)

const ErrorToast: React.FC<ToastProps> = ({ closeToast, message, title }) => (
  <ToastNotification
    hasIcon={true}
    severity='critical'
    onCloseClick={closeToast}
    title={title}
  >
    {message || 'Request Not Accepted'}
  </ToastNotification>
)

const ImportantToast: React.FC<ToastProps> = ({ closeToast, message, title, detailsText, detailsClick, color }) => (
  <ToastNotification
    hasIcon={true}
    severity='important'
    onCloseClick={closeToast}
    onDetailsClick={detailsClick}
    detailsText={detailsText}
    title={title}
    color={color}
  >
    {message}
  </ToastNotification>
)

const SuperContainer = styled.div<React.CSSProperties>`
  overflow: hidden;
  display: inline-flex;
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
  @media (max-width: 720px) {
    flex-direction: column;
  }
  height:  ${props => props.height ? props.height : '100%'};
  width: 100%;
  justify-content: space-around;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
  padding: ${props => props.padding ? props.padding : '1em'};
  align-items: ${props => props.alignItems ? props.alignItems : 'auto'};
  & .scuf-col {padding:0;}
   @media (max-width:1618px){
    #shipping-label span {margin-bottom:1.5rem;}
  }

  @media (max-width:1795px){
    #operator-label span {margin-bottom:1.5rem;}
  }
  @media (max-width: 1440px){
    height: 70vh;
  }

`

const TableContainer = styled.div<React.CSSProperties>`
  display: inline-flex;
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
  overflow: hidden;
  overflow-y: ${props => props.overflowY ? props.overflowY : 'hidden'};
  flex: ${props => props.flex ? props.flex : 0.5};
  transition: flex 0.4s;
  padding-left: ${props => props.paddingLeft ? props.paddingLeft : '0px'};
  padding: ${props => props.padding ? props.padding : '0px'};
`

const TableContainerModal = styled.div<React.CSSProperties>`
  display: inline-flex;
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
  overflow: hidden;
  overflow-y: ${props => props.overflowY ? props.overflowY : 'hidden'};
  flex: ${props => props.flex ? props.flex : 0.5};
  transition: flex 0.4s;
  padding-left: ${props => props.paddingLeft ? props.paddingLeft : '0px'};
  padding: ${props => props.padding ? props.padding : '0px'};
  height:10vh !important;
`

const VerticalTableContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  overflow-y: hidden;
  height: 75vh;
  transition: flex-height 5s;
`

const PaneContainer = styled.div<React.CSSProperties>`
  display: inline-flex;
  flex-direction: column;
  height: auto;
  max-height: calc(100vh - 145px);
  flex: ${props => props.flex ? props.flex : 1.5};
`

const Capitalize = styled.div`
  text-transform: capitalize;
`

const SmallImg = styled.img`
  height: 100px;
  width: 100px;
`

const MediumImg = styled.img`
  height: auto;
  width: 150px;
`

const InfoColumn = styled.div<React.CSSProperties>`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  flex-direction: column;
  text-align: left;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin-bottom: 0.3em;
  margin-top: 0.3em;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`

// If want to wrap something within a card, and want to have a side content space
const ContentContainer = styled.div`
  overflow-y: hidden;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
`

const ContentCard = styled.div<React.CSSProperties>`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  margin: 0.5em;
  text-align: center;
  height: 90%;
`

// For smaller wrappers
const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5em;
  margin: 0.5em;
`

const InfoKey = styled.h4<React.CSSProperties>`
  font-size: 16px;
  margin-bottom: 0.15rem;
  padding: ${props => props.padding ? props.padding : 0};
  margin: ${props => props.margin ? props.margin : 0};
`

const InfoText = styled.p<React.CSSProperties>`
  padding: ${props => props.padding ? props.padding : 0};
  margin: ${props => props.margin ? props.margin : 0};
  font-size: 14px;
  color: ${props => props?.color ? props.color : ''};
  @media (max-width: 768px) {
    padding-right: 0.3rem;
  }
`

const InfoContainer = styled.div<React.CSSProperties>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-self: center;
  justify-content: flex-start;
  padding: ${props => props.padding ? props.padding : 'rem'};
`

const FlexInfoText = styled(InfoText)`
  display: flex;
  flex: 1;
`

const RoundImg = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
`

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center !important;
  padding: 0.75rem;
`

const InfoRow = styled.div<React.CSSProperties>`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: center;
  align-self: center;
  justify-items: center;
`

const InfoCard = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  justify-items: center;
`

const InfoSection = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: flex-start;
  margin: 10px;
`

const Title = styled.h3`
  text-transform: uppercase;
  font-size: 18px;
`

const SoftwareUpdateTitle = styled.h3`
  text-transform: capitalize;
  font-size: inherit;
`

const MainTitle = styled.h1<React.CSSProperties>`
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  margin-bottom: ${props => props.marginBottom && 0};
`

const Subtitle = styled.div<React.CSSProperties>`
  text-transform: uppercase;
  font-size: 16px;
  padding: ${props => props.padding ? props.padding : 0};
  margin: ${props => props.margin ? props.margin : 0};
`

const LoadingSpinner = styled.div`
  border: 5px solid rgba(48, 48, 48, 0.15);
  border-top: 5px solid black;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: spin 0.5s linear infinite;
`

const UpperCaseDataCell = styled.div`
  text-transform: uppercase;
`

const FormRow = styled.div<React.CSSProperties>`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  flex-direction: row;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: ${props => props.alignItems ? props.alignItems : 'flex-start'};
  align-self: auto;
  justify-items: center;
  vertical-align: middle;
  padding: ${props => props.padding || 0};
`

const FormColumn = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  flex: ${props => props.flex ? props.flex : 1};
  align-items: flex-start;
  align-self: auto;
  justify-items: center;
`

const FormInputContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  flex-direction: column;
  text-align: left;
  padding: ${props => props.padding ? props.padding : '0.5em'};
  max-width: 100%;
`

const CheckboxFormSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #ccc;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const CheckboxFormLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  padding-bottom: 29px;
  background-color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ span {
      background-color: rgb(23, 146, 229);
      &:after {
        display: block;
      }
    }
  }

  &:hover {
    > input ~ span {
      border: 1px solid #000;
    }
  }
`

const StyledActionBar = styled(ActionBar)`
  background-color: #f7f7f7;
  padding-left: 30px;
`
const StyledActionBarB = styled(StyledActionBar)`
  padding-bottom: 24px;
  color:#808080;
  font-weight:600;
`

const StyledWrap = styled.div`
  height: 100%;
  overflow-y: scroll ;
`

export interface CheckboxFormProps {
  text: string
  name: string
  id?: string
  disabled?: boolean
}

const CheckboxForm: React.FC<CheckboxFormProps> = (props) => {
  return (
    <CheckboxFormLabel>{props.text}
      <Field name={props.name} id={props.id} component='input' type='checkbox' disabled={props.disabled} />
      <CheckboxFormSpan />
    </CheckboxFormLabel>
  )
}

const CapitalizeCell = ({ field, rowData }: ICellData, textNotAvailable: string) => {
  const text = rowData[field] || textNotAvailable
  return (
    <UpperCaseDataCell
      title={text}
    >
      {text}
    </UpperCaseDataCell>
  )
}

const DateFromNowCell = ({ field, rowData }: ICellData, onlyPast: boolean, textNotAvailable: string) => {
  const date = rowData[field] && onlyPast
    ? moment(rowData[field]).diff(new Date(), 'seconds') > 0 ? new Date() : rowData[field]
    : rowData[field]

  const text = date ? moment(date).locale(window.navigator.language).fromNow() : textNotAvailable
  return (
    <div
      title={text}
    >
      {text}
    </div>
  )
}

const formatNewDate = (data: { cellData?: moment.MomentInput }) => {
  if (data.cellData) {
    return moment(data.cellData).locale(window.navigator.language).format('MMM DD YYYY HH:mm:SS')
  } else {
    return 'Not Available'
  }
}

const formatShortDate = ({ field, rowData }: ICellData, textNotAvailable: string) => {
  const text = rowData[field]
  if (moment(text).isValid()) {
    return moment(text).locale(window.navigator.language).format('MMM DD YYYY')
  } else {
    return text || textNotAvailable
  }
}

const DateLocationFormat = ({ field, rowData }: ICellData, textNotAvailable: string) => {
  if (rowData[field]) {
    return moment(rowData[field]).locale(window.navigator.language).format('DD/MM/YYYY HH:mm')
  } else {
    return textNotAvailable
  }
}

const formatDateString = ({ field, rowData }: ICellData, textNotAvailable: string) => {
  const text = rowData[field] ? moment(rowData[field]).locale(window.navigator.language).format('MMM DD YYYY HH:mm:SS.sss') : textNotAvailable

  return <Left>{text}</Left>
}

const Left = styled.div`
  text-align: left;
  text-transform: capitalize;
`

const LowerLeft = styled.div`
  text-align: left;
`

const formatLeft = ({ value }: {value: any}, textNotAvailable: string) => {
  if (value !== null && value !== undefined) {
    return <LowerLeft>{value}</LowerLeft>
  } else {
    return <LowerLeft>{textNotAvailable}</LowerLeft>
  }
}

const formatMiliseconds = ({ value }: {value: number}, textNotAvailable: string) => {
  if (value) {
    return <LowerLeft>{`${value.toFixed(2)} ms`}</LowerLeft>
  } else {
    return <LowerLeft>{textNotAvailable}</LowerLeft>
  }
}

const OverflowRenderer = ({ field, rowData }: ICellData, notAvailable: string) => {
  const text = (rowData[field] && rowData[field] !== 'Not Available') ? rowData[field] : notAvailable
  return (
    <Capitalize
      title={text}
    >
      {text}
    </Capitalize>
  )
}

const OFRenderer = (data: {cellData?: any}) => {
  const text = data?.cellData || 'Not Available'
  return (
    <Capitalize
      title={text}
    >
      {text}
    </Capitalize>
  )
}

const ListContainer = styled.div`
  max-height: 73vh;
  overflow-y: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: rgba(255,255,255,.1)
`

const FilterContainer = styled.div`
  max-height: 75vh;
  overflow-y: hidden;
  display: flex;
  flex: 0.35;
  flex-direction: column;
  margin-right: 1%;
`

export interface ListItemProps {
  selected?: boolean
}

const ListItem = styled.div<ListItemProps>`
  font-size: 14px;
  color: #303030;
  padding: 0.6em 1.5em 0 1.5em;
  background-color: ${props => props.selected ? 'rgba(18,116,183,.3)' : 'transparent'};
  &:hover {
    background-color: rgba(112,112,112,.2);
    cursor: pointer;
  }
`

const Line = styled.div`
  border-bottom: solid 1px #D0D0D0;
  padding-left: 1em;
  padding-right: 1em;
  padding: 0.6em 1.5em 0 1.5em;
`

const ListHeader = styled.div`
  font-size: 0.75rem;
  color: #303030;
  padding: 1.5em 1.5em 1.5em 2em;
  font-weight: 600;
`

// eslint-disable-next-line react/display-name
const ListRow = React.memo((props) => (
  <ListItem {...props}>
    {props.children}
    <Line />
  </ListItem>
))

const OverflowRowItem = (data: { cellData: any }, notAvailable: string) => {
  const { cellData } = data
  const text = (cellData && cellData !== 'Not Available') ? cellData : notAvailable
  return (
    // @ts-ignore
    <RowItem textTransform='capitalize'>
      {text}
    </RowItem>
  )
}

const ToolsIcon = (image: string, size: string) => {
  return (
    <img src={image} height={size} />
  )
}

export {
  UpperCaseDataCell,
  FilterContainer,
  SuperContainer,
  formatDateString,
  VerticalTableContainer,
  formatLeft,
  LowerLeft,
  formatMiliseconds,
  TableContainer,
  formatNewDate,
  formatShortDate,
  PaneContainer,
  Capitalize,
  OFRenderer,
  Left,
  SmallImg,
  MediumImg,
  InfoColumn,
  InfoCard,
  InfoContainer,
  ContentContainer,
  ContentCard,
  SectionContainer,
  InfoKey,
  InfoText,
  FlexInfoText,
  RoundImg,
  Profile,
  InfoRow,
  InfoSection,
  Title,
  MainTitle,
  Subtitle,
  LoadingSpinner,
  FormRow,
  FormColumn,
  FormInputContainer,
  CheckboxForm,
  CapitalizeCell,
  DateFromNowCell,
  SuccessToast,
  ErrorToast,
  ImportantToast,
  OverflowRenderer,
  ListContainer,
  ListRow,
  Line,
  ListHeader,
  OverflowRowItem,
  ToolsIcon,
  DateLocationFormat,
  StyledActionBar,
  StyledActionBarB,
  StyledWrap,
  TableContainerModal,
  SoftwareUpdateTitle
}
