import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { translate } from 'react-translate'

import { Icon, Loader, Card } from '@scuf/common'

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 100%;
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
`

const ErrorText = styled.div`
  color: #b0b0b0;
  font-size: 40px;
  line-height: 1em;
  text-align: center;
  &::first-letter {
    text-transform: capitalize;
  }
`

class Error extends Component {
  render () {
    const { children, icon, iconProps, textProps, loading, defaultText, t } = this.props
    return (
      loading
        ? <ErrorWrapper>
          <Card>
            <Loader />
          </Card>
        </ErrorWrapper>
        : <ErrorWrapper>
          <ErrorContainer>
            <Icon name={icon} className='Error-Icon' exactSize={80} {...iconProps} color='#be271c' />
            <ErrorText className='Error-Text' {...textProps}>
              {children || t(defaultText)}
            </ErrorText>
          </ErrorContainer>
        </ErrorWrapper>
    )
  }
}

Error.propTypes = {
  icon: PropTypes.string,
  defaultText: PropTypes.string,
  textProps: PropTypes.object,
  iconProps: PropTypes.object,
  loading: PropTypes.bool
}

Error.defaultProps = {
  icon: 'badge-warning',
  defaultText: 'Not Available',
  iconProps: {},
  loading: false
}

export default translate('Error')(Error)
