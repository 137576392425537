import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from "../../../../Components/AssetManagementTable"
import { HealthWarning, CycleWarning, AgeWarning } from '../../../DashboardCard/DashboardCard.styles'
import { ModalTitle, ModalWrap } from './battery-list-modal.styles'
import { Link } from 'react-router-dom'

export const columns = [
  { field: 'serialNumber', translation: 'AssetManagementTable_serialNumber', render: null },
  { field: 'batteryPercentage', translation: 'AssetManagementTable_lowHealth', render: ({ value }) => <HealthWarning value={value}>{value} %</HealthWarning> },
  { field: 'cycleCount', translation: 'AssetManagementTable_cycles', render: ({ value }) => <CycleWarning value={value}>{value}</CycleWarning> },
  { field: 'batteryAge', translation: 'AssetManagementTable_age', render: ({ value }) => <AgeWarning value={value}>{value}</AgeWarning> },
  { field: 'currentDevice', translation: 'AssetManagementTable_deviceName', render: ({ value }) => <Link to={`/assets/management/devices/${value}`}>{value}</Link> },
  { field: 'currentDeviceAlias', translation: 'AssetManagementTable_deviceAlias', render: null },
  { field: 'calculatedManufactureDate', translation: 'AssetManagementTable_manufactureDate', render: null },
  { field: 'deviceSite', translation: 'AssetManagementTable_deviceSite', render: null }
]

const BatteryListModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>{t('BatteryReplaceSoon_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable columns={columns} data={data} fileName={t('BatteryReplaceSoon_title')} />
    </Modal.Content>
  </ModalWrap>
)

export default BatteryListModal
