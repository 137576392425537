import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from '@scuf/common'
import { defaultFlags, flagsPropTypes } from 'Utils/launch-darkly-flags'
import { SimpleDiv } from './assets-details.styles'
import { Tooltip } from '@scuf/common'
import { useTranslate } from 'react-translate'

const OptionsContainer = styled.div`
display: flex;
flex-direction: column;
position: absolute;
right: 0;
width: fit-content;
text-align: left;
background-color: white;
min-width: 10rem;
`
const CollapseContainer = styled.div`
position: relative;
width: 83px;
color: #303030;
height: 22px;
font-size: 14px;
font-weight: 500;
line-height: 22px;
text-align: right;
margin-right: 10px;
padding-right: 1.25rem;
@media (max-width: 768px) {
  margin: 0rem 0rem 0.5rem 3rem;
}
`
const CategoryContainer = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;
font-size: .875rem;
font-weight: 700;
color: #303030;
`

export const UnlicensedTooltip = ({
  content = 'renew',
  position,
  disabled,
  children
}) => {
  const t = useTranslate('UnlicensedTooltip')
  return (
    <Tooltip
      disabled={disabled}
      content={t(content)}
      element={<div>{children}</div>}
      event='hover'
      hoverable={true}
      position={position}
    />
  )
}

const Collapse = ({
  category,
  children,
  flags
}) => {
  const [ select, setSelect ] = useState(false)
  return (
    <CollapseContainer>
      <CategoryContainer onClick={() => setSelect(!select)}>
        {category}
        <Icon name='caret-down' size='medium' />
      </CategoryContainer>
      <OptionsContainer>
        {select && children}
      </OptionsContainer>
    </CollapseContainer>
  )
}

export const MaintenanceButton = ({
  t,
  onClick,
  flags,
  content = 'renew',
  deviceDbStatus
}) => {
  const tt = useTranslate('UnlicensedTooltip')
  const isLicensingEnabled = (flags.licensing && deviceDbStatus === 'unlicensed')
  return (
    <CollapseContainer>
      <UnlicensedTooltip
        position='top center'
        content= 'renew'
        disabled={deviceDbStatus === 'active'}
        >
          <SimpleDiv className={ isLicensingEnabled  ? 'disableOptions' : 'pointer'}>
            <CategoryContainer onClick={onClick}>
              <Icon name='tools' size='medium' />
              {t('Maintenance')}
            </CategoryContainer>
          </SimpleDiv>
        </UnlicensedTooltip>
    </CollapseContainer>
  )
}

Collapse.defaultProps = {
  flags: defaultFlags,
}
MaintenanceButton.defaultProps = {
  flags: defaultFlags,
}
MaintenanceButton.propTypes = {
  flags:flagsPropTypes,
}
UnlicensedTooltip.defaultProps = {
  flags: defaultFlags,
}
UnlicensedTooltip.propTypes = {
  flags:flagsPropTypes,
}
Collapse.propTypes = {
  category: PropTypes.string,
  children: PropTypes.node,
  flags:flagsPropTypes,
} 
export default Collapse