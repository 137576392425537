import React from 'react'
import styled from 'styled-components'
import { Modal, Icon, Badge, Footer } from '@scuf/common'

export const MainTitle = styled.div`
  color: #404040;
  font-size: 26px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin-left: -0.4rem;
  margin-right: 1%;
  min-height: 5.5rem;
  flex-wrap: wrap;
`
export const StyledIcon = styled(Icon)`
  display: flex;
  align-content: center;
  padding: 5px;
  width: 30px;
  height: 30px;

`
export const Title = styled.div`
  margin: 1.5rem 0.5rem 1.55rem 1.35rem;
  align-self: center;
  font-size: 26px;
`
export const LicenseTitle =styled.div`
color: #404040;
font-size: 20px;
line-height: 36px;
font-weight: 500;
display: flex;
justify-content: space-between;
margin-left: 1.9rem;
margin-right: 1%;
min-height: 0.5rem;
flex-wrap: wrap;
align-items: center;
`
export const AliasInput = styled.input`
  margin: 1.65rem 0.65rem 1.65rem 1.35rem;
  border: none;
  align-self: center;
  background: none;
  height: 34px;
  border-color: ${props => props.edited ? props.valid ? 'green' : 'red' : 'none'};
  border-width: 1px;
  border-style: solid;
  max-width: inherit;
  &:placeholder-shown {
    float: left;
  }
  &:focus {
    outline: none;
  }
`
export const ValidatorIcon = styled(Icon)`
  cursor: pointer;
  padding-bottom: ${props => props.name === 'edit' ? '0.35rem' : 'none'};
`

export const badgeRenderer = (status) => {
  const color = status === null ? 'grey' : status ? 'green' : 'red'
  return <Badge color={color} empty />
}

export const Container = styled.div`
  height: calc(90vh - 3.25vh);
  overflow-y: auto;
  overflow-x: hidden;
`

export const HigherModal = styled(Modal.Content)`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
`
export const AssetTabsContainer = styled.div`
margin: '1rem'
& .div.ui.pointing.secondary.ui.scuf-tab.menu{
  display: flex;
  flex-wrap: wrap;
}
`
export const AssetsTabsContainer = styled.div`
margin: 1rem;
min-height: 60vh;
& .ui.secondary.menu {
  @media (max-width: 320px) {
    display: flex;
    flex-wrap: wrap;
  }
}
`
export const TitleContainer = styled.div`
display: flex;
flex-direction: row; 
align-items: center;
margin-bottom: -2.5rem;
font-size: 20px;
`
export const Actions = styled.div`
display: flex;
cursor: pointer;
font-size: 1rem;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
`
export const OptionContainer = styled.div`
display: flex;
border-bottom: solid 1px gainsboro;
`
export const SimpleDiv =styled.div`

`
