import React from 'react'
import { Modal } from '@scuf/common'
import AssetManagementTable from '../../../AssetManagementTable'
import { ModalWrap, ModalTitle } from './new-batteries-list-modal.styles'
import { Link } from 'react-router-dom'

export const columns = [
  { field: 'serialNumber', translation: 'AssetManagementTable_serialNumber', render: null },
  { field: 'currentDevice', translation: 'AssetManagementTable_deviceName', render: ({ value }) => <Link to={`/assets/management/devices/${value}`}>{value}</Link> },
  { field: 'currentDeviceAlias', translation: 'AssetManagementTable_deviceAlias', render: null },
  { field: 'batterySite', translation: 'AssetManagementTable_deviceSite', render: null }
]

const NewBatteriesListModal = ({ data, t }) => (
  <ModalWrap>
    <ModalTitle>{t('NewBatteries_title')}</ModalTitle>
    <Modal.Content>
      <AssetManagementTable data={data} columns={columns} fileName={t('NewBatteries_title')} />
    </Modal.Content>
  </ModalWrap>
)

export default NewBatteriesListModal
