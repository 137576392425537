import styled from 'styled-components'
import { Badge } from "@scuf/common";


export const FilterContainer = styled.div`
display: flex;
width: 100% !important;
margin: 0.5rem
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const RadioContainer = styled.div`
  margin-bottom: 0.25rem;
`
export const CheckboxContainer = styled.div`
  margin-top: 0.25rem;
`

export const FilterBadge = styled.div`
background-color: #1792E5;
height: 1rem;
width: 1rem;
border-radius: 0.625rem;
margin-top: 2.3rem;
`