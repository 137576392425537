import React, { useEffect, useState, useCallback } from 'react'

import { useDebounce } from 'Components/AssetManagementBase/components/assets-table/assets-table.hooks'
import { DeviceLastOnline } from 'Components/AssetManagementBase/components/device-last-status/device-last-status'
import DeviceName from 'Components/AssetManagementBase/components/device-name'
import DataTable from 'Components/DataTable'
import { getFilterCount } from 'Components/DataTable/data-table.helpers'

import { StyledDataTable } from './asset-selector.styles'
import Filters from './filters'

import '@scuf/datatable/honeywell-compact/theme.css'

let nonSelectedDev = [];
let selectedDev = [];
let dataLength = 0;
const DevicesTable = ({
  devices,
  assetId,
  allowSelect,
  showSearch,
  selectionChangeHandle,
  tableRef,
  loadNextPage,
  searchChangeHandle,
  totalRows,
  pageSize,
  filter,
  setFilter,
  setLoading,
  t }) => {

  // To initialize the values only on the first render and reset selections.
  useEffect(() => {
    nonSelectedDev = [];
    selectedDev = [];
    dataLength = 0;
  }, [])

  const [sortDirection, setsortDirection] = useState('')
  const [sortBy, setSortBy] = useState('')

  const [searchTerm, setSearchTerm] = useState(undefined)
  const search = useDebounce(searchTerm, 500)

  const [filterCount, setFilterCount] = useState(undefined)

  const _handleSelection = useCallback(data => {
    // For every new row selection
    if (data.length > dataLength) {
      const idOfLastSelectedDevice = data.at(-1)
      if (selectedDev?.findIndex(x => x?.id === idOfLastSelectedDevice?.id) === -1) {
        selectedDev.push(idOfLastSelectedDevice)
      }
      dataLength = data.length
      selectionChangeHandle(selectedDev)
    } else if (data.length <= dataLength) { // For every row deselection
      selectedDev = selectedDev.filter(s => data.some(d => d.id === s.id))
      dataLength = data.length
    }
  }, [selectedDev, devices])

  useEffect(() => {
    if (search !== undefined) {
      searchChangeHandle(search)
    }
  }, [search])

  useEffect(() => {
    if (filter) {
      setFilterCount(getFilterCount(filter))
    }
  }, [filter])

  nonSelectedDev = devices?.filter(el => {
    return !selectedDev?.find(element => {
      return element?.id === el?.id;
    });
  })

  return (
    <div style={{ height: '50vh', maxWidth: '99%' }}>
      <StyledDataTable
        data={[...selectedDev, ...nonSelectedDev]}
        rows={pageSize}
        totalRows={totalRows}
        search={showSearch}
        resizableColumns={true}
        columnResizeMode='expand'
        selectedItems={selectedDev}
        selection={allowSelect}
        selectionMode={allowSelect ? 'multiple' : undefined}
        onSelectionChange={selectionChangeHandle}
        searchPlaceholder={t('Search for')}
        loadNextPage={loadNextPage}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={({ sortBy, sortDirection }) => {
          setSortBy(sortBy)
          setsortDirection(sortDirection)
        }}
        onSearchChange={setSearchTerm}
        searchFilterValue={search}
        ref={tableRef}
        filtersRenderer={allowSelect ? () =>
          <Filters
            assetId={assetId}
            filter={filter}
            setFilter={setFilter}
            setLoading={setLoading}
            t={t}
          /> : undefined}
        totalFilters={filterCount}
        keepSelectionOnClose={true}
        onSelectionV2={_handleSelection}
        isIndexBasedSelection={false}
      >
        <DataTable.Column
          dataKey='type'
          cellRenderer={props => <DeviceName {...props} />}
          label={t('Type')}
          align='start'
          initialWidth={120}
        />
        ,
        <DataTable.Column
          dataKey='name'
          label={t('Device Name')}
          initialWidth={200}
        />
        ,
        <DataTable.Column
          dataKey='displayModel'
          label={t('Model')}
          initialWidth={120}
        />
        ,
        <DataTable.Column
          dataKey='serialNumber'
          label={t('Serial Number')}
          initialWidth={200}
        />
        ,
        <DataTable.Column
          cellRenderer={({ rowData }) => <DeviceLastOnline {...rowData} />}
          dataKey='lastOnline'
          label={t('Status')}
          initialWidth={200}
        />
        ,
        <DataTable.Column
          cellRenderer={props => props.cellData ? props.cellData : t('N/A')}
          dataKey='firmwareVersion'
          label={t('Firmware')}
          initialWidth={200}
        />
        ,
        <DataTable.Column
          dataKey='site'
          label={t('Hierarchy')}
          initialWidth={200}
        />
      </StyledDataTable>
    </div>
  )
}

export default DevicesTable
