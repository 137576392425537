import { select } from '@redux-saga/core/effects'
import cryptojs from 'crypto-js'
import get from 'lodash/get'
import moment from 'moment'
import { normalize, schema } from 'normalizr'
import React from 'react'
import { toast } from 'react-toastify'
import { change } from 'redux-form'
import { all, call, put } from 'redux-saga/effects'

import { MODAL_TOAST_CONTAINER } from 'Utils/constants'

import { CONFIGURATION_MANAGEMENT_MODAL } from '../Components/ConfigurationManagement/configuration-management.constants'
import { FILE_NAME, FILE_TYPES } from '../Components/UploadAssetModal/upload-asset-modal.constants'
import ModalActions from '../Redux/ModalRedux'
import UpdatesActions from '../Redux/UpdatesRedux'
import { DeviceApi, UserApi } from '../Services'
import { ErrorToast, SuccessToast } from '../Themes/ScufStyledComponents'

export const fromBytesToHuman = bytes => {
  if (!bytes || isNaN(bytes)) {
    return bytes || 'Unknown'
  }
  if (bytes > (1024 * 999)) {
    return (bytes / (1024 ** 2)).toFixed(2) + ' MB'
  }
  if (bytes > 1024) {
    return (bytes / 1024).toFixed(2) + ' KB'
  } else {
    return bytes + ' B'
  }
}

export const getFileType = assetType => {
  const regmatch = /(\w+)-(\w+).*/.exec(assetType)
  const typeFormatted = get(regmatch, '1', assetType)
  const fileType = get(regmatch, '2', 'asset')

  switch (typeFormatted) {
    case FILE_TYPES.FOTA:
      return FILE_NAME.FOTA
    case FILE_TYPES.APPOTA:
      return FILE_NAME.APPOTA
    case FILE_TYPES.DEVCONFIG:
      switch (fileType) {
        case 'font':
          return 'Font file'
        case 'text':
          return 'Configuration file'
        case 'image':
          return 'Image file'
        case 'application':
          return 'Application file'
        default:
          return assetType
      }
    default:
      return assetType
  }
}

export const distribution = new schema.Entity('distribution', {}, {
  processStrategy: entity => {
    const regmatch = /(\w+)-(\w+).*/.exec(entity.type)
    const typeFormatted = get(regmatch, '1', entity.type)
    const fileType = get(regmatch, '2', 'asset')
    const releaseDate = moment(entity.releaseDate)
    return {
      ...entity,
      compatibleDevices: entity.compatibleDeviceModels.join(', '),
      typeFormatted: getFileType(entity.type),
      fileType,
      selected: false,
      fileSize: fromBytesToHuman(entity.size),
      name: entity.description || entity.name,
      releaseDate: releaseDate.isValid() ? releaseDate.utc().format() : '0',
      createdBy: entity.properties?.CreatedBy
    }
  }
})

export function * updatesRequestAssets (api) {
  try {
    const response = yield call(api.getAllAssetsV2)
    if (response.ok) {
      const data = response.data ? normalize(response.data.data, [distribution]) : { result: [], entities: {} }
      yield put(UpdatesActions.updatesSuccessAssets(data))
    } else {
      yield put(UpdatesActions.updatesFailureAssets())
    }
  } catch (e) {
    yield put(UpdatesActions.updatesFailureAssets())
  }
}

export function * loadDevicesForAssets (api, { selectedUpdates }) {
  const queries = selectedUpdates.map(id => call(getDevicesForAssets, api, id))
  yield all(queries)
}

export function * deletePackageRequest (api, { ids }) {
  try {
    const response = yield call(api.deletePackage, {}, ids[0])
    if (response.ok) {
      yield call(toast,
        <SuccessToast />, {
          containerId: CONFIGURATION_MANAGEMENT_MODAL
        })
      yield put(UpdatesActions.updatesSuccessDeletePackage(ids))
      yield call(updatesRequestAssets, DeviceApi)
    } else {
      yield put(UpdatesActions.updatesFailureList())
      yield call(toast,
        <ErrorToast
          message={response.originalError?.response.statusText || response.problem || undefined}
        />, {
          containerId: CONFIGURATION_MANAGEMENT_MODAL
        })
    }
  } catch (e) {
    yield put(UpdatesActions.updatesFailureList())
    yield call(toast,
      <ErrorToast
        message={e}
      />, {
        containerId: CONFIGURATION_MANAGEMENT_MODAL
      })
  }
}

export function * editPackageRequest (api, { data, id }) {
  try {
    const response = yield call(api.editPackage, data, id)
    if (response.ok) {
      yield all([
        put(ModalActions.modalClose()),
        put(UpdatesActions.updatesSuccessEditPackage(data, id)),
        call(toast,
          <SuccessToast />, {
            containerId: CONFIGURATION_MANAGEMENT_MODAL
          })
      ])
    } else {
      yield all([
        put(ModalActions.modalClose()),
        put(UpdatesActions.updatesFailureList()),
        call(toast,
          <ErrorToast
            message={response.originalError?.response.statusText || response.problem || undefined}
          />, {
            containerId: CONFIGURATION_MANAGEMENT_MODAL
          })
      ])
    }
  } catch (e) {
    yield all([
      put(ModalActions.modalClose()),
      put(UpdatesActions.updatesFailureList()),
      call(toast,
        <ErrorToast
          message={e}
        />, {
          containerId: CONFIGURATION_MANAGEMENT_MODAL
        })
    ])
  }
}

export function * updatesSchedulesRequest (api, { pageNumber, userIds }) {
  try {
    const response = yield call(api.getSchedules, pageNumber, userIds)
    if (response.ok) {
      let data
      if (response.status === 204) {
        data = []
      } else {
        data = get(response, 'data', [])
          .map(task => {
            return {
              ...task,
              displayName: task.displayName || 'Not available',
              assetCategory: getFileType(task.assetType)
            }
          })
      }
      if (pageNumber > 1) {
        yield put(UpdatesActions.updatesSchedulesSuccessAppend(data))
      } else {
        yield put(UpdatesActions.updatesSchedulesSuccess(data))
      }
    } else {
      yield put(UpdatesActions.updatesSchedulesFailure())
      if (/5.*/.test(response.status)) {
        yield call(toast,
          <ErrorToast
            message={response.data || response.problem || undefined}
          />, {
            containerId: MODAL_TOAST_CONTAINER
          })
      }
    }
  } catch (error) {
    console.error(error)
    yield put(UpdatesActions.updatesSchedulesFailure())
  }
}

export function* updatesCancelRequest(api, { task }) {
  const response = yield call(api.cancelFirmwareUpdate, [task])
  if (response.ok) {
    yield call(toast,
      <SuccessToast />, {
        containerId: CONFIGURATION_MANAGEMENT_MODAL
    })
    const user = yield select(state => {
      return state.login?.userName ?? ''
    })
    yield put(UpdatesActions.updatesSchedulesRequest(1, user))
  } else {
    yield put(UpdatesActions.updatesSchedulesFailure())
    yield call(toast,
      <ErrorToast
        message={response.data || response.problem || undefined}
      />, {
        containerId: CONFIGURATION_MANAGEMENT_MODAL
    })
  }
}

export function * uploadGetUploadUrl (api, { data }) {
  const { file, type, description, checksum, fileChecksum, compatibleVersions, releaseNote, releaseDate, isHash256, ...rest } = data
  const hashAlgorithm = isHash256 === true ? 'SHA256' : 'SHA1'
  const hash = isHash256 === true ? fileChecksum : checksum
  const [head, ...types] = type.split('-')
  const compatibleDeviceModels = data.compatibleDeviceModels && data.compatibleDeviceModels.length
    ? data.compatibleDeviceModels.join(',')
    : undefined
  const mimeType = types.join('-')
  const payload = {
    checksum: hash,
    checksumAlgorithm: hashAlgorithm,
    description,
    mimeType,
    name: file.name,
    size: file.size,
    createdDateTime: moment().utc(),
    modifiedDateTime: moment().utc(),
    releaseDate,
    notes: releaseNote,
    properties: {
      ...rest,
      compatibleDeviceModels
    }
  }
  const response = yield call(api.getUploadUrl, payload)
  if (response.ok) {
    return response
  } else {
    yield call(toast,
      <ErrorToast
        message={response.originalError?.message || response.problem || response.data || undefined}
      />, {
        containerId: MODAL_TOAST_CONTAINER
      })
  }
  return response
}

export function * updateUploadFileToUrl (api, { uploadPostUrl, urlParts }, { data, config }) {
  const { file } = data
  return yield call(api.uploadFileToUrl, uploadPostUrl, file, urlParts, config)
}

export function * linkFileToPackage (api, id, { data }, token, honeywellUpdatesAPI = false, isHash256 = false) {
  const { file, type, checksum, compatibleVersions, releaseNote, releaseDate, compatibleDeviceType, compatibleDeviceModels, description, fileChecksum, ...rest } = data
  const hashAlgorithm = isHash256 === true ? 'SHA256' : 'SHA1'
  const hash = isHash256 === true ? fileChecksum : checksum
  const compatible = compatibleVersions
    ? compatibleVersions.split(',')
    : undefined
  const payload = {
    ...rest,
    compatibleDeviceType,
    compatibleDeviceModels,
    description,
    checksum: hash,
    checksumAlgorithm: hashAlgorithm,
    compatibleVersions: compatible,
    type,
    name: file.name,
    size: file.size,
    releaseDate: honeywellUpdatesAPI ? releaseDate : moment().utc(),
    notes: releaseNote,
    properties: {
      ...rest
    },
    links: {
      files: '/api/v2/files/' + id
    },
    file: null
  }
  const response = yield call(api.uploadPackageFile, payload, {}, token, honeywellUpdatesAPI)
  if (!response.ok) {
    yield call(toast,
      <ErrorToast
        message={response.data || response.problem || undefined}
      />, {
        containerId: MODAL_TOAST_CONTAINER
      })
  }
  return response
}

let lastOffset = 0
export function callbackRead (reader, file, evt, callbackProgress, callbackFinal) {
  if (lastOffset === reader.offset) {
    // in order chunk
    lastOffset = reader.offset + reader.size
    callbackProgress(evt.target.result)
    if (reader.offset + reader.size >= file.size) {
      callbackFinal()
    }
  } else {
    // not in order chunk
    setTimeout(() => {
      callbackRead(reader, file, evt, callbackProgress, callbackFinal)
    }, 10)
  }
}

export function loading (file, callbackProgress, callbackFinal) {
  const chunkSize = 1024 * 1024
  let offset = 0
  const size = chunkSize
  let index = 0

  if (file.size === 0) {
    callbackFinal()
  }
  while (offset < file.size) {
    const partial = file.slice(offset, offset + size)
    const reader = new FileReader()
    reader.size = chunkSize
    reader.offset = offset
    reader.index = index
    reader.onload = function (evt) {
      callbackRead(this, file, evt, callbackProgress, callbackFinal)
    }
    reader.readAsArrayBuffer(partial)
    offset += chunkSize
    index += 1
  }
}

export const fileReaderHashHelper = file => new Promise((resolve, reject) => {
  const sha1 = cryptojs.algo.SHA1.create()
  let counter = 0
  lastOffset = 0
  loading(file,
    (data) => {
      const wordBuffer = cryptojs.lib.WordArray.create(data)
      sha1.update(wordBuffer)
      counter += data.byteLength
    }, (data) => {
      const encrypted = sha1.finalize().toString()
      resolve(encrypted)
    })
})

export const fileReaderHashHelperSHA256 = file => new Promise((resolve, reject) => {
  const sha256 = cryptojs.algo.SHA256.create();
  let counter = 0
  lastOffset = 0
  loading(file,
    (data) => {
      const wordBuffer = cryptojs.lib.WordArray.create(data)
      sha256.update(wordBuffer)
      counter += data.byteLength
    }, (data) => {
      const encrypted = sha256.finalize().toString()
      resolve(encrypted)
    })
})

export function * calculateHash ({ data: { file } }) {
  return yield call(fileReaderHashHelper, file)
}

export function * calculateHash256 ({ data: { file } }) {
  return yield call(fileReaderHashHelperSHA256, file)
}

export function * updatesCalculateHashRequest ({file, isHash256}) {

  if (!file) {
    yield put(UpdatesActions.updatesCalculateHashSuccess(''))
    yield put(change('uploadAssetForm', 'fileChecksum', ''))
    yield put(change('uploadAssetForm', 'checksum', ''))
    return
  }
  let hash = ''
  let hash256 = ''
  try {
    if (isHash256 === false){
      hash = yield call(calculateHash, { data: { file } })
      yield put(UpdatesActions.updatesCalculateHashSuccess(hash))
      yield put(change('uploadAssetForm', 'checksum', hash))
      yield put(change('uploadAssetForm', 'fileChecksum', ''))
    }
      hash256 = yield call(calculateHash256, { data: { file } })
      yield put(UpdatesActions.updatesCalculateHashSuccess(hash256))
      yield put(change('uploadAssetForm', 'fileChecksum', hash256))
      hash = yield call(calculateHash, { data: { file } })
      yield put(UpdatesActions.updatesCalculateHashSuccess(hash))
      yield put(change('uploadAssetForm', 'checksum', hash))
  } catch (error) {
    yield put(UpdatesActions.updatesCalculateHashFailure())
    yield call(toast, <ErrorToast message='Checksum Error' />, {
      containerId: MODAL_TOAST_CONTAINER
    })
  }
}

export function * updatesUploadAssetRequest (api, action) {
  const [urlResponse, requestToken] = yield all([
    call(
      uploadGetUploadUrl,
      api,
      {...action, data:{...action.data,  isHash256: action.isHash256}}
    ),
    call(UserApi.getUploadToken, { expiresAfter: '0.05' })
  ])
  const token = requestToken.ok ? get(requestToken, 'data.userJwtToken', undefined) : undefined
  if (urlResponse.ok) {
    const response = yield call(
      updateUploadFileToUrl,
      api,
      urlResponse.data,
      action
    )
    if (response.ok) {
      const linkResponse = yield call(
        linkFileToPackage,
        api,
        urlResponse.data.id,
        action,
        token,
        action.isHoneywellUpdate,
        action.isHash256
      )
      if (linkResponse.ok) {
        yield all([
          put(ModalActions.modalClose()),
          put(UpdatesActions.updatesUploadAssetSuccess())
        ])
        yield call(toast, <SuccessToast message={'Successfully Uploaded Software'} />, {
          containerId: MODAL_TOAST_CONTAINER
        })
        yield call(updatesRequestAssets, DeviceApi)
      } else {
        yield put(UpdatesActions.updatesUploadAssetFailure())
      }
    } else {
      yield put(UpdatesActions.updatesUploadAssetFailure())
      yield call(toast,
        <ErrorToast
          message={response.data || response.problem || undefined}
        />, {
          containerId: MODAL_TOAST_CONTAINER
        })
    }
  } else {
    yield put(UpdatesActions.updatesUploadAssetFailure())
  }
}

export const assetsDevicesSelector = ({ updates, devices }) => {
  const assets = updates.getIn(['updates', 'entities', 'distribution'], {})
  const devicesList = updates.getIn(['devices'], [])
    .map(id => {
      const d = devices.getIn(['devices', id], {})
      return {
        DeviceSerialNumber: d.serialNumber,
        SystemGuid: d.systemGuid || '',
        OperationType: '',
        CurrentVersion: d.firmwareVersion,
        firmwareVersion: d.firmwareVersion,
        SiteId: d.hierarchy
      }
    })
  return {
    assets,
    devices: devicesList
  }
}

export const devicesPayloadForFirmwareUpdate = ({ updates }, data) => {
  return updates.getIn(['selectedUpdates'], [])
    .map(id => updates.getIn(['updates', 'entities', 'distribution', id], {}))
    .reduce((arr, f) => {
      return [
        ...arr,
        ...data.form.AssetSelector.Devices
          .map(device => {
            return {
              DeviceSerialNumber: get(device, 'serialNumber', ''),
              SystemGuid: get(device, 'properties.SystemGuid', ''),
              OperationType: '',
              CurrentVersion: get(device, 'firmwareVersion', ''),
              firmwareVersion: get(device, 'firmwareVersion', ''),
              ScheduledVersion: f.version || '1.1',
              AssetType: f.type,
              displayName: f.description,
              assetCategory: f.typeFormatted,
              caidcAssetId: f.id,
              currentVersion: '1.0',
              SiteId: get(device, 'siteId', ''),
              siteName: get(device, 'siteHierarchy', ''),
              CommandParameters: {
                DeviceType: f.compatibleDeviceType,
                VerificationCode: f.checksum,
                ChecksumType: f.checksumAlgorithm,
                FileName: f.name,
                FileType: f.fileType,
                FileSize: f.fileSize
              }
            }
          })
      ]
    }, [])
}

export const tasksRequest = (assetList, devices, assets) => {
  return assetList.reduce((arr, asset) => {
    const form = get(asset, 'form', {})
    return [
      ...arr,
      ...devices.map(device => {
        const f = assets[asset.id]
        return {
          ...device,
          ScheduledVersion: f.version || '1.1',
          displayName: f.displayName || f.name,
          assetCategory: f.typeFormatted,
          AssetType: f.type,
          caidcAssetId: f.id,
          currentVersion: '1.0',
          CommandParameters: {
            ...form,
            DeviceType: f.compatibleDeviceType,
            VerificationCode: f.checksum,
            ChecksumType: f.checksumAlgorithm,
            FileName: f.name,
            FileType: f.fileType,
            FileSize: f.fileSize
          }
        }
      })
    ]
  }, [])
}

export const getAssetIdCommanParams = asset => {
  const commandParams = {
    AssetId: asset.software.id,
    AssetName: asset.software.name,
    UpdateName: asset.software.jobName
  }
  if (asset.form[asset.software.id]?.drive) {
    return {
      ...commandParams,
      ...asset.form[asset.software.id]
    }
  }
  return commandParams
}

const getAssetDetailsCommandParams = asset => ({ AssetId: asset.id, AssetName: asset.name, ...asset.form })

export function * updatesPrinterProvisionRequest (api, { task }) {
  const { assets, devices } = yield select(assetsDevicesSelector)
  const deviceList = tasksRequest(task.assetList, devices, assets)
  try {
    const response = yield call(api.updateFirmware, {
      requestPriority: 'Immediate',
      deviceList,
      cronExpression: task.details.cronExpression,
      retryUntilTime: task.details.endTime,
      CommandParameters: getAssetDetailsCommandParams(task.assetList[0]),
      updatePreferences: {
        "WiFi": task.updatePreferences?.WiFi ?? false,
        "WWAN": task.updatePreferences?.WWAN ?? false,
        "Ethernet": task.updatePreferences?.Ethernet ?? false,
      },
      Tags: [],
      scheduleTime: task.details.startTime,
      scheduleTimeZone: task.details.timeZone
    })
    if (response.ok && response.data) {
      yield all([
        put(UpdatesActions.updatesSchedulesRequest()),
        yield call(toast, <SuccessToast message={response.data} />, {
          containerId: MODAL_TOAST_CONTAINER
        })
      ])
    } else {
      yield put(UpdatesActions.updatesSchedulesFailure())
      yield call(toast,
        <ErrorToast
          message={response.problem || undefined}
        />, {
          containerId: MODAL_TOAST_CONTAINER
        })
    }
  } catch (error) {
    yield put(UpdatesActions.updatesSchedulesFailure())
    yield call(toast, <ErrorToast />, {
      containerId: MODAL_TOAST_CONTAINER
    })
  }
}

export function* updateFirmware(api, payload) {
  try {
    const response = yield call(api, payload)
    if (response.ok && response.data) {
      const user = yield select(state => {
        return state.login?.userName ?? ''
      })
      yield all([
        put(UpdatesActions.updatesSchedulesRequest(1, user)),
        call(toast, <SuccessToast message={response.data} />, {
          containerId: MODAL_TOAST_CONTAINER
        })
      ])
    } else {
      yield put(UpdatesActions.updatesSchedulesFailure())
      yield call(toast, <ErrorToast message={response.problem || undefined} />)
    }
  } catch (error) {
    yield put(UpdatesActions.updatesSchedulesFailure())
    yield call(toast, <ErrorToast message={error.message} />)
  }
}

export function * updatesUpdateAllRequest (api, { data }) {
  const { devices } = yield select(state => {
    return {
      devices: devicesPayloadForFirmwareUpdate(state, data)
    }
  })
  const form = data.form.AssetSelector
  const mode = form.selectMode
  yield call(updateFirmware, api.updateFirmware, {
    requestPriority: 'Immediate',
    tags: mode === 1 ? form.Tags.map(tag => ({ tag: `${tag.key}:${tag.val}`, type: 'device' })) : undefined,
    deviceList: mode === 2 ? devices : undefined,
    applicableSites: mode === 3 ? form.Sites : undefined,
    scheduleTime: data.finalDateTime,
    cronExpression: data.cronExpression,
    retryUntilTime: data.finalEndTime,
    scheduleTimeZone: data.timeZone,
    updatePreferences: {
      "WiFi": data.form.UpdatePreferences?.WiFi ? data.form.UpdatePreferences.WiFi : false,
      "WWAN": data.form.UpdatePreferences?.WWAN ? data.form.UpdatePreferences.WWAN : false,
      "Ethernet": data.form.UpdatePreferences?.Ethernet ? data.form.UpdatePreferences.Ethernet : false,
    },
    CommandParameters: {
      ...getAssetIdCommanParams(data),
      includeDownstreamSites: form.includeChildSites
    }
  })
}

export function * updateSoftwareFromFile (api, payload) {
  const response = yield call(api, payload)
  if(response.ok) {
    yield all([
      put(UpdatesActions.updatesUpdateFromFileSuccess()),
      call(toast, <SuccessToast message={response.data} />, {
        containerId: MODAL_TOAST_CONTAINER
      })
    ])
  } else {
    yield all([
      put(UpdatesActions.updatesUpdateFromFileFailure()),
      call(toast, <ErrorToast message={response.problem || undefined} />)
    ])
  }
}

export function * updatesUpdateFromFileRequest(api, { data, file }) {
  try {
    const formData = new FormData()
    const params = {
      requestPriority: 'Immediate',
      scheduleTime: data.finalDateTime,
      cronExpression: data.cronExpression,
      retryUntilTime: data.finalEndTime,
      scheduleTimeZone: data.timeZone,
      updatePreferences: {
        "WiFi": data.form.UpdatePreferences?.WiFi ? data.form.UpdatePreferences.WiFi : false,
        "WWAN": data.form.UpdatePreferences?.WWAN ? data.form.UpdatePreferences.WWAN : false,
        "Ethernet": data.form.UpdatePreferences?.Ethernet ? data.form.UpdatePreferences.Ethernet : false,
      },
      CommandParameters: {
        ...getAssetIdCommanParams(data)
      }
    }
    formData.append('file', file)
    formData.append('request', JSON.stringify(params))
    yield call(updateSoftwareFromFile, api.updateSoftwareFromFile, formData)
  } catch (error) {
    yield all([
      put(UpdatesActions.updatesUpdateFromFileFailure()),
      call(toast, <ErrorToast message={error.message} />)
    ])
  }
}

export function * getDevicesForAssets (api, assetId) {
  const response = yield call(api.getDevicesForAssets, assetId, {
    page: 1, pageSize: 1000
  })
  if (response.ok) {
    const devices = get(response, 'data.devices', [])
      .map(
        device => {
          return {
            ...device.deviceIdentifier,
            ...device.deviceDetail,
            ...device
          }
        })
    yield put(UpdatesActions.updatesSelectUpdatesSuccess(assetId, { ...response.data, devices }))
  } else {
    yield put(UpdatesActions.updatesSchedulesFailure())
    yield call(toast,
      <ErrorToast
        message={response.data || response.problem || undefined}
      />, {
        containerId: MODAL_TOAST_CONTAINER
      })
  }
}

export function * updatesUpdateSelectedRequest (api, { data }) {
  const devices = yield select(state => devicesPayloadForFirmwareUpdate(state, data))
  const scheduleTime = data.finalDateTime ? data.finalDateTime : moment()
  const scheduleTimeZone = data.timeZone
  yield call(updateFirmware, api.updateFirmware, {
    requestPriority: 'Immediate',
    deviceList: devices,
    scheduleTime,
    scheduleTimeZone,
    CommandParameters: getAssetIdCommanParams(data)
  })
}
