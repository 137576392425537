export class SoftwareUpdateService {
  constructor (api) {
    this.api = api
  }

  retryScheduledTask = (masterId, payload) => {
    return this.api.put(`/schedules/${masterId}/retry-commands`, payload)
  }

  sendDownloadRequest= (scheduleId, payload) => {
    return this.api.get(`/schedules/${scheduleId}/export-tasks`, payload)
  }

  getFormatedScheulesData (tasks) {
    return tasks?.map(task => ({
      ...task,
      ...task.assetDetails
    }))
  }

  getSchedules = async (pageNumber, userIds) => {
    const userEmails = userIds?.toString()
    const response = await this.api.get('/schedules', { pageNumber: pageNumber, userIds: userEmails })
    if (response.ok) {
      return {
        ...response,
        data: this.getFormatedScheulesData(response.data)
      }
    }
    return response
  }

  getDevicesFromJob = (jobId, filters) => {
    return this.api.get(`/schedules/${jobId}/tasks`, filters)
  }
  
  getUsers = () => {
    return this.api.get('/schedules/users')
  }
}
