import { takeLatest, all, fork, takeEvery } from 'redux-saga/effects'

/* ------------- Types ------------- */

import { LoginTypes } from 'Redux/LoginRedux'
import { UserTypes } from 'Redux/UserRedux'
import { DeviceTypes } from 'Redux/DeviceRedux'
import { UserManagerTypes } from 'Redux/UserManagerRedux'
import { SiteManagerTypes } from 'Redux/SiteManagerRedux'
import { BatteryTypes } from 'Redux/BatteryRedux'
import { FirmwareTypes } from 'Redux/FirmwareRedux'
import { DeviceTrendsTypes } from 'Redux/DeviceTrendsRedux'
import { ReportTypes } from 'Redux/ReportRedux'
import { LocationTypes } from 'Redux/LocationRedux'
import { BiReportTypes } from 'Redux/BiReportRedux'
import { DashboardTypes } from 'Redux/DashboardRedux'
import { AlertsTypes } from 'Redux/AlertsRedux'
import { PreferencesTypes } from 'Redux/PreferencesRedux'
import { UpdatesTypes } from 'Redux/UpdatesRedux'
import { UserSettingsTypes } from 'Redux/UserSettingsRedux'
import { LoggingTypes } from 'Redux/LogRedux'
import { RemoteAccessTypes } from 'Redux/RemoteAccessRedux'
import { SiteComparisonTypes } from 'Redux/SiteComparisonRedux'
import { CounterTypes } from 'Redux/CounterRedux'
import { DeviceActionsTypes } from 'Redux/DeviceActionsRedux'
import { SsoOauthActions } from 'features/sso-oauth2/store'
import { EndUserLicenseAgreementActions } from 'features/end-user-license/end-user-license.reducer'
import { OrganizationUserActions } from 'features/user-management/users-list/organization-users-reducer'
import { LocationTimelineActions } from '../Components/LocationDisplay/location-display.reducer.js'
/* ------------- Sagas ------------- */

import rootSaga from 'Store/root-saga'
import {
  login,
  logOutAfterError,
  logOut,
  closeAllRemoteAccessWindows,
  fetchOauthTokenFullfiled,
  fetchOauthTokenRejected,
  extendSession,
  autoExtendSession
} from './LoginSagas'
import {
  getEulaVerification,
  sendEulaValidation
} from 'features/end-user-license/end-user-license.sagas'
import { userInfo, userDetails } from './UserSagas'
import {
  getLocations,
  updateLocation,
  createBuilding,
  createZone,
  createSubZone,
  getDevicesByLocation,
  deleteLocation,
  updateBuilding,
  updateZone,
  getDevicesById
} from './LocationSagas'
import {
  getDevices,
  getDevicesCount,
  getOnlineCount,
  getDisconnectedCount,
  getAllBatteriesHealth,
  getAllBatteriesCycle,
  getAllAvgDrawn,
  getGateways,
  getDevice,
  getGateway,
  getTelemetry,
  getBatteriesDetails,
  updateDevices,
  provision,
  getFamilies,
  getPrinterMediaStatus,
  getPrinterRibbonStatus,
  updateDeviceAlias,
  getDevicesInBackground,
  generateQr,
  deviceSendRaCommand,
  updateBulkEditDevices,
  deviceSitefilter,
  getDevicesOutOfRange,
  getDevicesOutOfRangeAdditional
} from './DeviceSagas'
import {
  getUsers,
  updateUser,
  deleteUser,
  createUser,
  getRoles,
  getRegistrationRole,
  getUiRoles,
  getUsersByRole,
} from './UserManagerSagas'
import {
  getUsersList,
  getUsersListNextPage,
} from '../features/user-management/users-list/organization-users-sagas'
import {
  getSites,
  getSiteUsers,
  getSiteDevices,
  getSiteDetails,
  createSite,
  updateSite,
  deleteSite,
  removeUser,
  getRootSites,
  getSitesWithReports,
  getOrgFeatures,
  UpdateOrgFeatures
} from './SitesManagerSagas'
import {
  getBatteries,
  getBatteryHealth,
  getBatteryLevels
} from './BatterySagas'
import {
  getModels,
  getDevicesByModel,
  getFirmwareByModel,
  getAllAssetsInfo,
  getAssetsInfo,
  getScheduled,
  firmwareRequestAssetsForDevice,
  firmwareRequestToUpdate
} from './FirmwareSagas'
import {
  getDeviceTrends,
  getTelemetryByCategory
} from './DeviceTrendsSagas'
import {
  calculateTco,
  calculateHaas,
  reportGet,
  reportGetField,
  reportSave,
  reportGetAll,
  reportSubmitDevice,
  reportSubmitTerm,
  changePricingModel,
  getTemplates,
  getEventReports,
  getAdditionalEventReports
} from './ReportSagas'
import {
  getReportToken,
  getDeviceTypeBySiteId
} from './BiReportSagas'
import {
  getAvgBatteryAgeCard,
  getDeviceUsageLevel,
  getPrinterUsageLevelByMeters,
  getMobilePrinterUsageLevelByMeters,
  getScanPullsCard,
  getPrintersOutOfPaperCard,
  getPrintersOutOfRibbonCard,
  getAvgBatteryCycleCard,
  getPtcDevicesUseCard,
  getHardRebootsCard,
  getBatterySwapsCard,
  getAlerts as getAlertsDashboard,
  getPrinterUsageLevelByLabels,
  getMobilePrinterUsageLevelByLabels,
  getAgingBatteriesCard,
  getBatteriesLowHealthCard,
  getDistributionOfDipsCard,
  getTotalDevicesCard,
  getTotalDevicesWithBatteriesCard,
  getTotalDevicesWithoutBatteriesCard,
  getTotalBatteriesCard,
  getFixedDevicesOfflineCard,
  getBatteriesCycleAboveCard,
  getBatteriesHealthBelowCard,
  getBatteriesHealthBetweenCard,
  getTypesOfDevicesCard,
  getTypesOfModelsCard,
  getFirmwareCard,
  getScannerFirmwareCard,
  getOsCard,
  getBatteriesLowHealthEventsCard,
  getGenericBatteryHealth,
  getTotalDevicesStatus,
  getAvgBatteryChargeCard,
  getDroppedDevicesCard,
  getPrintersFaultyDots,
  getScanVolumeCard,
  getPrintVolumeCard,
  getHardUseDevicesCard,
  getScanVolumeByDeviceCard,
  getPrintVolumeByDeviceCard,
  getSecurityPatch,
  getHardRebootsByDevice,
  getUsagePerDayByDevice,
  getPrintedMetersByDevice,
  getDroppedDevicesByDevice,
  getNewBatteriesLastMonthCard,
  getEventsForPrintersCard,
  getDeviceAppData,
  getDeviceAppDataCustom,
  getTotalBatteriesDetails,
  getTotalBatteriesDetailsNextPage,
  getNotReportingBatteries,
  getNotReportingBatteriesNextPage,
  deleteBatteries,
  addBatteries,
  getDeletedBatteriesCard,
  getDeletedBatteriesNextPage,
  getDeletedBatteries,
  getApplicationUsageTime,
  getNetworkIO,
  getApplicationUsageDetails,
  getApplicationUsageNextPage,
  getNetworkIONextPage,
  getHardUseDevicesDetails,
  getDeepSleepsByDevice,
  getErrorsByDevice,
  getInventoryCyclesByDevice,
  getApplicationUsageDetailsNextPage,
  getNotReportingBatteriesCard,
  getDeleteBatteries,
  deleteBatteriesNotReporting,
  getLostAndFoundCard,
  getLostAndFoundDetails,
  getApplicationDisruptionsCard,
  getApplicationDisruptionsCardByDevice,
  getApplicationDisruptionsDetails,
  getApplicationDisruptionsDetailsNextPage,
  getApplicationDisruptionsByAppCard,
  getApplicationDisruptionsByAppDetails,
  getApplicationDisruptionsByAppDetailsNextPage
} from './DashboardSagas'
import {
  getDeviceLocationHistoryList,
  getDeviceLocationHistoryListNextPage,
} from '../Components/LocationDisplay/location-display.sagas.js'
import {
  getAlerts,
  markAsRead,
  deleteAlerts,
  deleteAlertsRecommended,
  getUnreadAlerts
} from './AlertSagas'

import {
  saveEmailPreference,
  editEmailPreferences,
  deleteEmailPreferences,
  getEmailPreferences,
  getPreferencesAlertsData,
  putAlertValueRequest
} from './PreferencesSagas'

import {
  updatesRequestAssets,
  updatesSchedulesRequest,
  updatesCancelRequest,
  updatesUploadAssetRequest,
  updatesPrinterProvisionRequest,
  updatesUpdateAllRequest,
  updatesUpdateFromFileRequest,
  updatesUpdateSelectedRequest,
  updatesCalculateHashRequest,
  loadDevicesForAssets,
  deletePackageRequest,
  editPackageRequest
} from './UpdatesSagas'

import {
  getUserSettingsFilters,
  setUserSettingsFilters,
  updateUserSettingsFilters,
  getDashboardList,
  getDashboardFullList,
  getDashboardsPerUserFullList,
  editDashboard,
  deleteDashboard,
  assignDashboard,
  deleteDashboardView,
  createNewDashboard
} from './UserSettingsSagas'

import {
  getActivityLogReport,
  getAditionalActivityLogReport
} from './LogSagas'

import {
  getSiteRankings,
  getSiteComparison
} from './SiteComparisonSagas'

import {
  getRemoteAccessInProgress
} from './RemoteAccessSagas'

import {
  getTotalCountRequest,
  updateLimit,
  changeCurrentCount
} from './CounterSagas'

/* ------------- API ------------- */
import {
  LoginApi,
  LogOutApi,
  UserApi,
  DeviceApi,
  UserManagerApi,
  SitesManagerApi,
  BatteryApi,
  ReportApi,
  LocationApi,
  BiReportApi,
  DashboardApi,
  AlertsApi,
  PreferencesApi,
  FilesApi,
  BulkProvisionApi,
  UserSettingsApi,
  TypeApi,
  LogApi,
  RemoteAccessApi,
  SiteComparisonApi,
  CounterApi,
  SoftwareUpdateApi,
  DeviceActionsApi,
  RenewTokenApi
} from 'Services'

import {
  selectDeviceAction,
  triggerActionCommand,
  triggerLogActionCommand,
  pullDeviceLogs,
  getLatestTelemetry,
  getLastHeartbeat,
  refreshConnectionStatus
} from './DeviceActionsSaga'
import { SubscriptionAgreementActions } from 'features/subscription-agreement/subscription-agreement.reducer.js'
import { getSLAVerification, sendSLAValidation } from 'features/subscription-agreement/subscription-agreement.sagas.js'

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    fork(rootSaga),
    takeLatest(LoginTypes.LOGIN_SIGN_IN_REQUEST, login, LoginApi),
    takeLatest(LoginTypes.LOGIN_REFRESH_TOKEN_REQUEST, extendSession, RenewTokenApi),
    takeLatest(LoginTypes.LOGIN_REFRESH_TOKEN_REQUEST_AUTO, autoExtendSession, RenewTokenApi),
    takeLatest(LoginTypes.LOGIN_LOGOUT_SENTIENCE, logOutAfterError, LogOutApi),
    takeLatest(LoginTypes.LOGIN_LOGOUT_REQUEST, logOut),
    takeLatest(LoginTypes.LOGIN_LOGOUT_REQUEST, closeAllRemoteAccessWindows),
    takeLatest(UserTypes.USER_REQUEST, userInfo, UserApi),
    takeLatest(UserTypes.USER_DETAILS_REQUEST, userDetails, UserApi),
    takeLatest(SubscriptionAgreementActions.initialSLARequest.type, getSLAVerification, UserApi),
    takeLatest(SubscriptionAgreementActions.initialSLAVerificationRequest.type, sendSLAValidation, UserApi),
    takeLatest(EndUserLicenseAgreementActions.initialEULARequest.type, getEulaVerification, UserApi),
    takeLatest(EndUserLicenseAgreementActions.initialEULAVerificationRequest.type, sendEulaValidation, UserApi),
    takeLatest(DeviceTypes.DEVICE_REQUEST, getDevices, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_SET_REQUEST, getDevice, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_SET_GATEWAY, getGateway, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_REQUEST_COUNT, getDevicesCount, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_REQUEST_ONLINE, getOnlineCount, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_REQUEST_DISCONNECTED, getDisconnectedCount, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_REQUEST_BATTERY, getAllBatteriesHealth, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_REQUEST_CYCLE, getAllBatteriesCycle, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_REQUEST_AVERAGE, getAllAvgDrawn, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_REQUEST_GATEWAYS, getGateways, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_RESET_REQUEST_GATEWAYS, getGateways, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_REQUEST_REFRESH, getGateways, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_GET_TELEMETRY, getTelemetry, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_REQUEST_BATTERIES, getBatteriesDetails, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_UPDATE_REQUEST, updateDevices, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_UPLOAD_PROVISION_REQUEST, provision, BulkProvisionApi),
    takeLatest(DeviceTypes.DEVICE_FAMILIES_AND_MODELS_REQUEST, getFamilies, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_UPDATE_ALIAS_REQUEST, updateDeviceAlias, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_BACKGROUND_REQUEST_ALL, getDevicesInBackground, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_QR_REQUEST, generateQr, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_SEND_RA_COMMAND_ACTION, deviceSendRaCommand, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_SITE_FILTER, deviceSitefilter, TypeApi),
    takeLatest(UserManagerTypes.USER_MANAGER_REQUEST, getUsers, UserManagerApi),
    takeLatest(UserManagerTypes.USER_MANAGER_UPDATE_REQUEST, updateUser, UserManagerApi),
    takeLatest(UserManagerTypes.USER_MANAGER_DELETE_REQUEST, deleteUser, UserManagerApi),
    takeLatest(UserManagerTypes.USER_MANAGER_CREATE_REQUEST, createUser, UserManagerApi),
    takeLatest(UserManagerTypes.USER_MANAGER_ROLES_REQUEST, getRoles, UserManagerApi),
    takeLatest(UserManagerTypes.USER_MANAGER_ROLES_UI_REQUEST, getUiRoles, UserManagerApi),
    takeLatest(UserManagerTypes.USER_MANAGER_USERS_BY_ROLE_REQUEST, getUsersByRole, UserManagerApi),
    takeLatest(UserManagerTypes.USER_MANAGER_REGISTRATION_ROLE_REQUEST, getRegistrationRole, UserManagerApi),
    takeLatest(OrganizationUserActions.initialUsersRequest.type, getUsersList, UserManagerApi),
    takeLatest(OrganizationUserActions.initialUsersNextPageRequest.type, getUsersListNextPage, UserManagerApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_REQUEST, getSites, SitesManagerApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_CREATE_SITE, createSite, SitesManagerApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_REQUEST_CURRENT, getSiteDetails, SitesManagerApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_USERS_REQUEST, getSiteUsers, SitesManagerApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_DEVICES_REQUEST, getSiteDevices, SitesManagerApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_UPDATE_REQUEST, updateSite, SitesManagerApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_DELETE_REQUEST, deleteSite, SitesManagerApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_REMOVE_USER_REQUEST, removeUser, SitesManagerApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_ROOT_SITES_REQUEST, getRootSites, SitesManagerApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_REQUEST_REPORTS, getSitesWithReports, SitesManagerApi),
    takeLatest(BatteryTypes.BATTERY_REQUEST, getBatteries, BatteryApi),
    takeLatest(BatteryTypes.BATTERY_HEALTH_REQUEST, getBatteryHealth, BatteryApi),
    takeLatest(BatteryTypes.BATTERY_LEVELS_REQUEST, getBatteryLevels, BatteryApi),
    takeLatest(FirmwareTypes.FIRMWARE_REQUEST_MODELS, getModels, DeviceApi),
    takeLatest(FirmwareTypes.FIRMWARE_REQUEST_DEVICES, getDevicesByModel, DeviceApi),
    takeLatest(FirmwareTypes.FIRMWARE_REQUEST_VERSIONS, getFirmwareByModel, DeviceApi),
    takeLatest(FirmwareTypes.FIRMWARE_REQUEST_ALL_ASSETS, getAllAssetsInfo, DeviceApi),
    takeLatest(FirmwareTypes.FIRMWARE_REQUEST_ASSETS, getAssetsInfo, DeviceApi),
    takeLatest(FirmwareTypes.FIRMWARE_REQUEST_SCHEDULED, getScheduled, SoftwareUpdateApi),
    takeLatest(FirmwareTypes.FIRMWARE_REQUEST_TO_UPDATE, firmwareRequestToUpdate, DeviceApi),
    takeLatest(DeviceTrendsTypes.DEVICE_TRENDS_REQUEST_ALL, getDeviceTrends, DeviceApi),
    takeLatest(DeviceTrendsTypes.DEVICE_TRENDS_REQUEST_CATEGORY, getTelemetryByCategory, DeviceApi),
    takeLatest(ReportTypes.REPORT_REQUEST_SAVE, reportSave, ReportApi),
    takeLatest(ReportTypes.REPORT_REQUEST_GET_ALL, reportGetAll, ReportApi),
    takeLatest(ReportTypes.REPORT_CALCULATE_TCO, calculateTco, ReportApi),
    takeLatest(ReportTypes.REPORT_CALCULATE_HAAS, calculateHaas, ReportApi),
    takeLatest(ReportTypes.REPORT_REQUEST_GET_ID, reportGet, ReportApi),
    takeLatest(ReportTypes.REPORT_REQUEST_SUBMIT_TERM, reportSubmitTerm, ReportApi),
    takeLatest(ReportTypes.REPORT_REQUEST_SUBMIT_DEVICE, reportSubmitDevice, ReportApi),
    takeLatest(ReportTypes.REPORT_GET_FIELD, reportGetField, ReportApi),
    takeLatest(ReportTypes.REPORT_REQUEST_PRICING_MODEL_UPDATE, changePricingModel, ReportApi),
    takeLatest(ReportTypes.GET_TEMPLATES, getTemplates, ReportApi),
    takeLatest(ReportTypes.GET_EVENT_REPORTS, getEventReports, ReportApi),
    takeLatest(ReportTypes.GET_ADDITIONAL_EVENT_REPORTS, getAdditionalEventReports, ReportApi),
    takeLatest(BiReportTypes.BIREPORT_GET_REPORT_TOKEN, getReportToken, BiReportApi),
    takeLatest(BiReportTypes.BIREPORT_DEVICE_TYPE_BY_SITE, getDeviceTypeBySiteId, BiReportApi),
    takeLatest(LocationTypes.LOCATION_REQUEST, getLocations, LocationApi),
    takeLatest(LocationTypes.LOCATION_REQUEST_DELETE, deleteLocation, LocationApi),
    takeLatest(LocationTypes.LOCATION_REQUEST_DEVICES, getDevicesByLocation, LocationApi),
    takeEvery(LocationTypes.LOCATION_REQUEST_DEVICES_BY_ID, getDevicesById, LocationApi),
    takeLatest(LocationTypes.LOCATION_SET_FILTER, getDevicesByLocation, LocationApi),
    takeLatest(LocationTypes.LOCATION_REQUEST_CREATE_BUILDING, createBuilding, LocationApi),
    takeLatest(LocationTypes.LOCATION_REQUEST_CREATE_ZONE, createZone, LocationApi),
    takeLatest(LocationTypes.LOCATION_REQUEST_UPDATE_ZONE, updateZone, LocationApi),
    takeLatest(LocationTypes.LOCATION_REQUEST_CREATE_SUB_ZONE, createSubZone, LocationApi),
    takeLatest(LocationTypes.LOCATION_REQUEST_UPDATE_BUILDING, updateBuilding, LocationApi),
    takeLatest(LocationTypes.LOCATION_REQUEST_UPDATE_LOCATION, updateLocation, LocationApi),
    takeLatest(AlertsTypes.ALERTS_REQUEST, getAlerts, AlertsApi),
    takeLatest(AlertsTypes.UNREAD_ALERTS_REQUEST, getUnreadAlerts, AlertsApi),
    takeLatest(AlertsTypes.ALERTS_MARK_READ_REQUEST, markAsRead, AlertsApi),
    takeLatest(AlertsTypes.ALERTS_CLEAN_SELECTED_ALERTS, deleteAlerts, AlertsApi),
    takeLatest(AlertsTypes.ALERTS_CLEAN_RECOMMENDED, deleteAlertsRecommended, AlertsApi),
    takeLatest(DeviceTypes.DEVICE_PRINTER_MEDIA_STATUS_REQUEST, getPrinterMediaStatus, DeviceApi),
    takeLatest(DeviceTypes.DEVICE_PRINTER_RIBBON_STATUS_REQUEST, getPrinterRibbonStatus, DeviceApi),
    takeLatest(PreferencesTypes.PREFERENCES_EMAIL_REQUEST, saveEmailPreference, PreferencesApi),
    takeLatest(PreferencesTypes.PREFERENCES_EMAIL_EDIT_REQUEST, editEmailPreferences, PreferencesApi),
    takeLatest(PreferencesTypes.PREFERENCES_EMAIL_DELETE_REQUEST, deleteEmailPreferences, PreferencesApi),
    takeLatest(PreferencesTypes.PREFERENCES_EMAIL_GET_REQUEST, getEmailPreferences, PreferencesApi),
    takeLatest(PreferencesTypes.PREFERENCES_ALERTS_DATA_REQUEST, getPreferencesAlertsData, PreferencesApi),
    takeEvery(PreferencesTypes.PREFERENCES_CHANGE_ALERT_VALUE_REQUEST, putAlertValueRequest, PreferencesApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_AGING_BATTERIES_CARD, getAgingBatteriesCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_AVG_BATTERY_AGE_CARD, getAvgBatteryAgeCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_AVG_BATTERY_CYCLE_CARD, getAvgBatteryCycleCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_SCAN_PULLS_CARD, getScanPullsCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_DEVICE_USAGE_LEVEL, getDeviceUsageLevel, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_PRINTER_USAGE_LEVEL_BY_METERS, getPrinterUsageLevelByMeters, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_MOBILE_PRINTER_USAGE_LEVEL_BY_METERS, getMobilePrinterUsageLevelByMeters, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_PRINTERS_OUT_OF_PAPER_CARD, getPrintersOutOfPaperCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_PRINTERS_OUT_OF_RIBBON_CARD, getPrintersOutOfRibbonCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_EVENTS_FOR_PRINTERS, getEventsForPrintersCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_PTC_DEVICES_USE, getPtcDevicesUseCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_HARD_REBOOTS_CARD, getHardRebootsCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_BATTERY_SWAPS, getBatterySwapsCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_ALERTS, getAlertsDashboard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_PRINTER_USAGE_LEVEL_BY_LABELS, getPrinterUsageLevelByLabels, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_MOBILE_PRINTER_USAGE_LEVEL_BY_LABELS, getMobilePrinterUsageLevelByLabels, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_BATTERIES_LOW_HEALTH_CARD, getBatteriesLowHealthCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_DISTRIBUTION_OF_DIPS_CARD, getDistributionOfDipsCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_TOTAL_DEVICES_CARD, getTotalDevicesCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_TOTAL_DEVICES_WITH_BATTERIES_CARD, getTotalDevicesWithBatteriesCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_TOTAL_BATTERIES_CARD, getTotalBatteriesCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_TOTAL_DEVICES_WITHOUT_BATTERIES_CARD, getTotalDevicesWithoutBatteriesCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_FIXED_DEVICES_OFFLINE_CARD, getFixedDevicesOfflineCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_BATTERIES_CYCLE_ABOVE_CARD, getBatteriesCycleAboveCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_BATTERIES_HEALTH_BETWEEN_CARD, getBatteriesHealthBelowCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_BATTERIES_HEALTH_BETWEEN_CARD, getBatteriesHealthBetweenCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_TYPES_OF_DEVICES_CARD, getTypesOfDevicesCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_TYPES_OF_MODELS_CARD, getTypesOfModelsCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_OS_CARD, getOsCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_BATTERIES_LOW_HEALTH_EVENTS_CARD, getBatteriesLowHealthEventsCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_GENERIC_REQUEST, getGenericBatteryHealth, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_TOTAL_DEVICES_STATUS, getTotalDevicesStatus, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_DROPPED_DEVICES_CARD_REQUEST, getDroppedDevicesCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_PRINTERS_FAULTY_DOTS, getPrintersFaultyDots, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_SCAN_VOLUME, getScanVolumeCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_LOST_AND_FOUND, getLostAndFoundCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_LOST_AND_FOUND_DETAILS, getLostAndFoundDetails, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS, getApplicationDisruptionsCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_BY_DEVICE, getApplicationDisruptionsCardByDevice, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_DETAILS, getApplicationDisruptionsDetails, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_DETAILS_NEXT_PAGE, getApplicationDisruptionsDetailsNextPage, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_BY_APP, getApplicationDisruptionsByAppCard, DashboardApi),
    //Saga for the table
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_NOT_REPORTING_BATTERIES, getNotReportingBatteries, DashboardApi),
    //Saga for the outside card
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_NOT_REPORTING_BATTERIES_NEXT_PAGE, getNotReportingBatteriesNextPage, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_NOT_REPORTING_BATTERIES_CARD, getNotReportingBatteriesCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_DELETED_BATTERIES_CARD, getDeletedBatteriesCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_DELETED_BATTERIES, getDeletedBatteries, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_DELETED_BATTERIES_NEXT_PAGE, getDeletedBatteriesNextPage, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APPLICATION_USAGE_CARD, getApplicationUsageTime, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_NETWORK_IO_CARD, getNetworkIO, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APPLICATION_USAGE_DETAILS, getApplicationUsageDetails, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APPLICATION_USAGE_DETAILS_NEXT_PAGE, getApplicationUsageDetailsNextPage, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APPLICATION_USAGE_CARD_NEXT_PAGE, getApplicationUsageNextPage, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_NETWORK_IO_NEXT_PAGE, getNetworkIONextPage, DashboardApi),
    takeLatest(LocationTimelineActions.initialPointsRequest.type, getDeviceLocationHistoryList, DashboardApi),
    takeLatest(LocationTimelineActions.initialPointsNextPageRequest.type, getDeviceLocationHistoryListNextPage, DashboardApi),
    takeLatest(UpdatesTypes.UPDATES_REQUEST_LIST, firmwareRequestAssetsForDevice, DeviceApi),
    takeLatest(UpdatesTypes.UPDATES_UPDATE_REQUEST_LIST, firmwareRequestAssetsForDevice, DeviceApi),
    takeLatest(UpdatesTypes.UPDATES_REQUEST_ASSETS, updatesRequestAssets, DeviceApi),
    takeLatest(UpdatesTypes.LOAD_DEVICES_FOR_ASSETS, loadDevicesForAssets, DeviceApi),
    takeLatest(UpdatesTypes.UPDATES_SCHEDULES_REQUEST, updatesSchedulesRequest, SoftwareUpdateApi),
    takeLatest(UpdatesTypes.UPDATES_CANCEL_REQUEST, updatesCancelRequest, DeviceApi),
    takeLatest(UpdatesTypes.UPDATES_UPLOAD_ASSET_REQUEST, updatesUploadAssetRequest, FilesApi),
    takeLatest(UpdatesTypes.UPDATES_PRINTER_PROVISION_REQUEST, updatesPrinterProvisionRequest, DeviceApi),
    takeLatest(UpdatesTypes.UPDATES_UPDATE_ALL_REQUEST, updatesUpdateAllRequest, DeviceApi),
    takeLatest(UpdatesTypes.UPDATES_UPDATE_FROM_FILE_REQUEST, updatesUpdateFromFileRequest, DeviceApi),
    takeLatest(UpdatesTypes.UPDATES_UPDATE_SELECTED_REQUEST, updatesUpdateSelectedRequest, DeviceApi),
    takeLatest(UpdatesTypes.UPDATES_CALCULATE_HASH_REQUEST, updatesCalculateHashRequest),
    takeLatest(UpdatesTypes.UPDATES_DELETE_PACKAGE_REQUEST, deletePackageRequest, DeviceApi),
    takeLatest(UpdatesTypes.UPDATES_EDIT_PACKAGE_REQUEST, editPackageRequest, DeviceApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_FIRMWARE_CARD, getFirmwareCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_SCANNER_FIRMWARE_CARD, getScannerFirmwareCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_PRINT_VOLUME_CARD, getPrintVolumeCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_HARD_USE_DEVICES_CARD, getHardUseDevicesCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_SCAN_VOLUME_BY_DEVICE, getScanVolumeByDeviceCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_PRINT_VOLUME_BY_DEVICE, getPrintVolumeByDeviceCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_SECURITY_PATCH, getSecurityPatch, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_HARD_REBOOTS_BY_DEVICE, getHardRebootsByDevice, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_ERRORS_BY_DEVICE, getErrorsByDevice, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_INVENTORY_CYCLES_BY_DEVICE, getInventoryCyclesByDevice, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APP_DATA, getDeviceAppData, DeviceApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APP_DATA_CUSTOM, getDeviceAppDataCustom, DeviceApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_USAGE_PER_DAY_BY_DEVICE, getUsagePerDayByDevice, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_PRINTED_METERS_BY_DEVICE, getPrintedMetersByDevice, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_DROPPED_DEVICES_BY_DEVICE, getDroppedDevicesByDevice, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_NEW_BATTERIES_LAST_MONTH_CARD, getNewBatteriesLastMonthCard, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_TOTAL_BATTERIES_DETAILS, getTotalBatteriesDetails, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_TOTAL_BATTERIES_DETAILS_NEXT_PAGE, getTotalBatteriesDetailsNextPage, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_DELETE_BATTERIES, deleteBatteries, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_DELETE_BATTERIES_NOT_REPORTING, deleteBatteriesNotReporting, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_ADD_BATTERIES, addBatteries, DashboardApi),
    takeLatest(DeviceTypes.UPDATE_BULK_EDIT_DATA_REQUEST, updateBulkEditDevices, DeviceApi),
    takeLatest(UserSettingsTypes.USER_SETTINGS_REQUEST_GET_FILTERS, getUserSettingsFilters, UserSettingsApi),
    takeLatest(UserSettingsTypes.USER_SETTINGS_REQUEST_SET_FILTERS, setUserSettingsFilters, UserSettingsApi),
    takeLatest(UserSettingsTypes.USER_SETTINGS_REQUEST_UPDATE_FILTERS, updateUserSettingsFilters, UserSettingsApi),
    takeLatest(UserSettingsTypes.DASHBOARD_REQUEST_LIST, getDashboardList, UserSettingsApi),
    takeLatest(UserSettingsTypes.DASHBOARD_OPTIONS_REQUEST, getDashboardList, UserSettingsApi),
    takeLatest(UserSettingsTypes.DASHBOARD_REQUEST_FOR_ROLE_LIST, getDashboardFullList, UserSettingsApi),
    takeLatest(UserSettingsTypes.DASHBOARD_EDIT_REQUEST, editDashboard, UserSettingsApi),
    takeLatest(UserSettingsTypes.DASHBOARD_REQUEST_USER_FOR_ROLE_LIST, getDashboardsPerUserFullList, UserSettingsApi),
    takeLatest(UserSettingsTypes.DASHBOARD_REVOKE_REQUEST, deleteDashboard, UserSettingsApi),
    takeLatest(UserSettingsTypes.DASHBOARD_ASSIGN_REQUEST, assignDashboard, UserSettingsApi),
    takeLatest(UserSettingsTypes.USER_SETTINGS_DELETE_DASHBOARD_VIEW, deleteDashboardView, UserSettingsApi),
    takeLatest(UserSettingsTypes.CREATE_NEW_DASHBOARD_REQUEST, createNewDashboard, UserSettingsApi),
    takeLatest(LoggingTypes.LOG_REQUEST_ACTIVITY_LOG, getActivityLogReport, LogApi),
    takeLatest(LoggingTypes.LOG_REQUEST_ADITIONAL_ACTIVITY_LOG, getAditionalActivityLogReport, LogApi),
    takeEvery(SiteComparisonTypes.SITE_RANKINGS_REQUEST, getSiteRankings, SiteComparisonApi),
    takeEvery(SiteComparisonTypes.SITE_COMPARISON_REQUEST, getSiteComparison, SiteComparisonApi),
    takeLatest(RemoteAccessTypes.REMOTE_ACCESS_IN_PROGRESS_REQUEST, getRemoteAccessInProgress, RemoteAccessApi),
    takeEvery(CounterTypes.GET_TOTAL_COUNT_REQUEST, getTotalCountRequest, CounterApi),
    takeEvery(CounterTypes.UPDATE_LIMIT, updateLimit, CounterApi),
    takeEvery(CounterTypes.CHANGE_CURRENT_COUNT, changeCurrentCount, CounterApi),
    takeLatest(DeviceTypes.DEVICES_OUT_OF_RANGE_REQUEST, getDevicesOutOfRange, DeviceApi),
    takeLatest(DeviceTypes.DEVICES_OUT_OF_RANGE_ADDITIONAL_REQUEST, getDevicesOutOfRangeAdditional, DeviceApi),
    takeLatest(DeviceActionsTypes.DEVICE_ACTION_REQUEST, selectDeviceAction, DeviceActionsApi),
    takeLatest(DeviceActionsTypes.TRIGGER_ACTION_COMMAND, triggerActionCommand, DeviceActionsApi),
    takeLatest(DeviceActionsTypes.TRIGGER_LOG_ACTION_COMMAND, triggerLogActionCommand, DeviceActionsApi),
    takeLatest(DeviceActionsTypes.PULL_DEVICE_LOGS, pullDeviceLogs, DeviceActionsApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_ORG_SETTINGS, getOrgFeatures, SitesManagerApi),
    takeLatest(SiteManagerTypes.SITE_MANAGER_UPDATE_ORG_SETTINGS, UpdateOrgFeatures, SitesManagerApi),
    takeLatest(SsoOauthActions.exchangeOauthCode.fulfilled.toString(), fetchOauthTokenFullfiled),
    takeLatest(SsoOauthActions.exchangeOauthCode.rejected.toString(), fetchOauthTokenRejected),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_HARD_USE_DEVICES_DETAILS, getHardUseDevicesDetails, DashboardApi),
    takeLatest(DeviceActionsTypes.GET_LATEST_TELEMETRY, getLatestTelemetry, DeviceActionsApi),
    takeLatest(DeviceActionsTypes.GET_LAST_HEARTBEAT, getLastHeartbeat, DeviceActionsApi),
    takeLatest(DeviceActionsTypes.REFRESH_CONNECTION_STATUS, refreshConnectionStatus, DeviceActionsApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_DEEP_SLEEP_DATA, getDeepSleepsByDevice, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_BY_APP_DETAILS, getApplicationDisruptionsByAppDetails, DashboardApi),
    takeLatest(DashboardTypes.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_BY_APP_DETAILS_NEXT_PAGE, getApplicationDisruptionsByAppDetailsNextPage, DashboardApi)
  ])
}
